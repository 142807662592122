import { SxProps } from '@mui/material';

export const getOMHeaderStyles = (roleShortHand: string): SxProps => ({
  whiteSpace: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  zIndex: 2,
  pl: roleShortHand.length === 1 ? '16px' : '8px',
});

export const AddOMButtonStyles: SxProps = {
  border: '1px solid #EBEBEB',
  backgroundColor: '#FFFFFF',
  boxShadow: 'none',
  height: '25px',
  width: '25px',
  '&:hover': {
    backgroundColor: '#EBEBEB !important',
  },
  '&:focused': {
    backgroundColor: '#E6E6E6 !important',
  },
  '&:disabled': {
    backgroundColor: '#D9D9D9 !important',
  },
};

export const AddOMButtonIconStyles: SxProps = {
  fontSize: '12px',
};
