import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  GENERIC_PAGE_SIZE_OPTIONS,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  SortDirection,
  DEFAULT_PAGE_SIZE,
  OrderStatus,
} from 'global-constants';
import {
  GridPaginationModel,
  GridRowId,
  GridSortModel,
  GridRowParams,
  GridCellParams,
  GridTreeNode,
} from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import { DataGridSx } from 'components/DataGrid/styles';
import { useAdminModePrompt } from 'hooks/useAdminModePrompt';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import {
  DEFAULT_ORDERS_DASH_SORT_MODEL,
  ORDER_INPUT_FIELDS,
} from 'pages/Orders/constants';
import { NewOrderItem, Order, OrderType, Shipment } from 'types';
import { OrdersDatagridProps, OrderWithShippingDetails } from './types';
import {
  GET_ORDER_ITEMS_BY_ORDER_ID,
  GET_RETRIEVAL_ORDER_TYPES,
  SEARCH_ORDERS_V2,
} from './queries';
import { useGetOrdersColumns } from './hooks/useGetOrdersColumns';
import CondenseIcon from './components/CondenseIcon';
import ExpandIcon from './components/ExpandIcon';
import ExpandedRowOrderItemDisplay from './components/ExpandedRowOrderItemDisplay';
import useGetOrdersDataGridState from 'pages/Orders/hooks/useGetOrdersDataGridState';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { selectOrderSearchState } from 'store/slices/orders/search/selectors';
import {
  selectAllChosenEntries,
  selectIsFiltered,
} from 'store/shared/selectors';
import {
  getFlattenedOrderItems,
  getWhereClauseFromFilterEntries,
} from './utils';
import { resetComboSearch } from 'store/slices/orders/search';
import useOrderOrAssetFilterSync from 'hooks/useOrderOrAssetFilterSync';
import { ShipmentStatuses } from 'globals/constants/shipment';
import { ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME } from 'components/DataGrid/constants';

const OrdersDatagrid = (props: OrdersDatagridProps) => {
  const {
    apiRef,
    paginationModel,
    setPaginationModel,
    ordersSort,
    setOrdersSort,
  } = useGetOrdersDataGridState();
  const userOrganization = useGetUserOrganization();
  const hasUserOrg = Boolean(userOrganization?.id) ?? false;
  const orderSearchState = useSelector(selectOrderSearchState);
  const hasOrderFilters = useSelector(selectIsFiltered('orders'));
  const selectedFilterEntries = useSelector(selectAllChosenEntries('orders'));
  const complexWhereClause = getWhereClauseFromFilterEntries(
    selectedFilterEntries
  );
  const {
    orderTypeFilter,
    statusFilter,
    orderNumberFilter,
    lookAheadOptionType,
    lookAheadOption,
  } = orderSearchState;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const viewParam = searchParams?.get('view') ?? 'ALL';

  useOrderOrAssetFilterSync('orders');

  const { isFlagOn: hasAccessOrderDetailsPage } = useFeatureFlagService(
    FeatureFlagNames.ORDER_ITEMS_DETAILS_VIEW
  );

  const hasOrderDetailsPagePermission = hasAccessOrderDetailsPage();

  // Row Hovering
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    if (!rowId) {
      console.warn(
        'Row id not found in event target. Are you populating the data-id attribute?'
      );
      return;
    }
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  //Expanded rows
  const [expandedRowIds, setExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [expandedRowData, setExpandedRowData] = React.useState<
    Record<string, NewOrderItem[]>
  >({});

  const [fetchOrderItemsByOrderId, { loading: isOrderItemsLoading }] =
    useSafeLazyQuery(GET_ORDER_ITEMS_BY_ORDER_ID);

  const columns = useGetOrdersColumns(hasOrderDetailsPagePermission, {
    rowHoveringModel,
    onFollowUpView: props.onFollowUpView,
  });

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...model, page: 0 });
    } else {
      setPaginationModel(model);
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (!sortModel.length) {
      setOrdersSort(DEFAULT_ORDERS_DASH_SORT_MODEL);
      return;
    }

    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setOrdersSort({
      direction: serverSortDirection,
      field,
    });
  };

  const handleExpandableRow = (params: GridRowParams<any>): React.ReactNode => {
    let displayOrderItems: NewOrderItem[] = [];
    const { id: paramsId = undefined } = params;

    if (paramsId && paramsId in expandedRowData) {
      displayOrderItems = expandedRowData[
        paramsId as keyof typeof expandedRowData
      ].map((orderItem: NewOrderItem) => ({
        ...orderItem,
        shipmentStatus: orderItem?.shipmentStatus ?? ShipmentStatuses.PENDING,
      }));
    }

    return (
      <ExpandedRowOrderItemDisplay
        key={params.id}
        orderItems={displayOrderItems}
        order={params.row}
      />
    );
  };

  const openOrCloseRow = (rowId: GridRowId) => {
    if (expandedRowIds.includes(rowId)) {
      const newArray = expandedRowIds.filter(
        (expandedRowId) => expandedRowId !== rowId
      );
      setExpandedRowIds(newArray);
    } else {
      const order = orders.find((order) => order.id === rowId);
      const flattenedShipments = order?.shipments;
      const orderItems = (order?.orderItems ?? []) as NewOrderItem[];
      const flattenedOrderItems = flattenedShipments?.length
        ? getFlattenedOrderItems(flattenedShipments)
        : orderItems;
      const flatItemsLookup = flattenedOrderItems.length
        ? Object.fromEntries(flattenedOrderItems.map((oi) => [oi.id, true]))
        : {};
      const unshippedOrderItems =
        flattenedOrderItems.length && orderItems.length
          ? orderItems
              .slice()
              .filter((orderItem) => !flatItemsLookup[orderItem.id])
          : ([] as NewOrderItem[]);

      if (flattenedOrderItems.length) {
        setExpandedRowData((prevData) => ({
          ...prevData,
          [rowId.toString()]: [...flattenedOrderItems, ...unshippedOrderItems],
        }));
        setExpandedRowIds((prevExpandedRowIds) => [
          ...prevExpandedRowIds,
          rowId,
        ]);
      }
    }
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    const rowId = params.id;
    openOrCloseRow(rowId);
  };

  const handleCellClick = (
    params: GridCellParams<any, unknown, unknown, GridTreeNode>
  ) => {
    const rowId = params.id;
    openOrCloseRow(rowId);
  };

  const { data: orderTypesData } = useSafeQuery(GET_RETRIEVAL_ORDER_TYPES);
  const retrievalOrderTypes: OrderType[] =
    orderTypesData?.searchOrderTypes.orderTypes ?? [];

  const orderBy = ordersSort
    ? {
        [ordersSort.field]:
          ordersSort.direction === 'orderDesc' ? 'desc' : 'asc',
      }
    : { createdAt: 'desc' };

  const { queryField: oldLookAheadField } =
    ORDER_INPUT_FIELDS[lookAheadOptionType as keyof typeof ORDER_INPUT_FIELDS];

  const hasSearchCombo =
    !!lookAheadOption && selectedFilterEntries.length === 0;

  const queryVariables = {
    where: {
      AND: [
        { createdAt: { gt: 1 } },
        {
          ...(hasUserOrg
            ? {
                organizationId: {
                  equals: userOrganization?.id,
                },
              }
            : {
                deletedAt: {
                  equals: null,
                },
              }),
        },
      ] as any,
    },
    orderBy,
    take: hasUserOrg ? paginationModel.pageSize : 0,
    skip: paginationModel.page * paginationModel.pageSize,
    // distinct: ['id'],
  };

  if (viewParam === 'FOLLOW_UP') {
    queryVariables.where.AND = [
      ...queryVariables.where.AND,
      {
        status: {
          in: [
            OrderStatus.PENDING_RECIPIENT_INFORMATION as string,
            OrderStatus.PENDING_RETURN as string,
          ],
        },
      },
    ];
  }

  if (viewParam === 'RETRIEVALS' && retrievalOrderTypes.length) {
    const retrievalOrderTypeIds = retrievalOrderTypes.map(({ id }) => id);

    queryVariables.where.AND = [
      ...queryVariables.where.AND,
      {
        orderTypeId: {
          in: retrievalOrderTypeIds,
        },
      },
    ];
  }

  if (hasSearchCombo) {
    const comboWhereClause =
      lookAheadOptionType === 'orderNumber'
        ? { orderNumber: { equals: lookAheadOption } }
        : {
            recipient: { is: { collaboratorId: { equals: lookAheadOption } } },
          };

    queryVariables.where.AND = [...queryVariables.where.AND, comboWhereClause];
  }

  if (complexWhereClause.length) {
    queryVariables.where.AND = [
      ...queryVariables.where.AND,
      ...complexWhereClause,
    ];
  }

  const { data, loading: isSearchOrdersLoading } = useSafeQuery(
    SEARCH_ORDERS_V2,
    {
      variables: queryVariables,
      skip: !retrievalOrderTypes.length,
      fetchPolicy: 'network-only',
    }
  );

  const rawOrders = data?.v2FindAndCountOrders?.orders ?? [];

  const orders: OrderWithShippingDetails[] = rawOrders.map((order: Order) => ({
    ...order,
    shipments: order.shipments?.map((shipment: Shipment) => ({
      ...shipment,
      orderItems: shipment.shipmentItems
        ?.filter((shipmentItem) => shipmentItem.orderItem)
        .map((shipmentItem) => shipmentItem.orderItem),
    })),
  }));

  const ordersCount = data?.v2FindAndCountOrders?.count ?? 0;

  const showOrderTableLoadingIndicator =
    isSearchOrdersLoading || isOrderItemsLoading || props.showLoadingIndicator;

  React.useEffect(() => {
    if (!props.followUpBannerShown) return;
    setPaginationModel({ ...paginationModel, pageSize: 10 });
  }, [props.followUpBannerShown]);

  React.useEffect(() => {
    if (props.followUpBannerShown) return;
    setPaginationModel(() => {
      const { pageSize, ...rest } = paginationModel;
      const newPageSize = pageSize === 10 ? DEFAULT_PAGE_SIZE : pageSize;
      return { ...rest, pageSize: newPageSize };
    });
  }, [props.followUpBannerShown]);

  React.useEffect(() => {
    if (!hasOrderFilters) return;
    dispatch(resetComboSearch());
  }, [hasOrderFilters]);

  useAdminModePrompt();

  return (
    <DataGrid
      apiRef={apiRef}
      pagination
      disableColumnMenu
      autoHeight={false}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handleOnPaginationModelChange}
      disableRowSelectionOnClick
      rows={orders}
      rowCount={ordersCount}
      columns={columns}
      loading={showOrderTableLoadingIndicator}
      pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
      slotProps={{
        panel: {
          placement: 'bottom-end',
          sx: DataGridSx,
        },
        row: {
          onMouseOver: handleOnRowOver,
          onMouseLeave: handleOnRowLeave,
        },
      }}
      getDetailPanelContent={handleExpandableRow}
      getDetailPanelHeight={() => 'auto'}
      slots={{
        detailPanelExpandIcon: ExpandIcon,
        detailPanelCollapseIcon: CondenseIcon,
      }}
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
      detailPanelExpandedRowIds={expandedRowIds}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      disableMultipleColumnsSorting
      classes={{
        virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
      }}
    />
  );
};

export default OrdersDatagrid;
