import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, MenuItem, Select } from '@mui/material';
import Box from '@mui/system/Box';
import { useForm } from 'react-hook-form';
import { sanitize } from 'dompurify';
import { notificationSlice } from 'store/slices';
import { executeNowThenWait } from 'services';
import { Button, SwipeableImagesView } from 'components';
import { ProductDetailsModalProps } from './types';
import { RETRIEVAL_PER_ITEM_CHARGE } from 'pages/Procurement/components/ProductCatalog/constants';
import {
  useProductCategoryControllerRetrieve,
  useProductDetailsControllerGetByProductId,
  useProductVariantByAttributesControllerGetByAttributes,
} from 'services/openapi/apiComponents';
import {
  getValidVariantValues,
  fixProductDetailsPrice,
  showDisclaimerAccordionSLA,
  hasRedundantOption,
  allWatchedFormValuesNonEmpty,
} from './utils';
import RetrievalInsuranceSelection from './components/RetrievalInsuranceSelection';
import { PROTECTION_PLAN_ATTRIBUTE_NAME } from './constants';
import { RETAIL_PRICE } from 'pages/BuyAndHold/components/MarketplaceView/constants';
import { NO_PROTECTION_PLAN } from './components/LaptopProtectionPlan/constants';
import { PdpSlideUpTransition } from './transitions';
import FieldInputWrapper from './components/FieldInputWrapper';
import DisclaimerSLA from './components/DisclaimerSLA';
import LaptopProtectionPlan from './components/LaptopProtectionPlan';
import { isProductCategory } from 'services/product';
import { ProductCategory } from 'global-constants';
import * as ProductDetailsModalStyles from './styles';
import { ProductOption } from 'services/openapi/apiSchemas';

const ProductDetailsModal = (props: ProductDetailsModalProps) => {
  const styles = ProductDetailsModalStyles;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [productVariantQuantity, setProductVariantQuantity] =
    React.useState<number>(1);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  React.useEffect(() => {
    if (!props.productCatalogItem) props.handleClose();
  }, []);

  const { data: productDetailsData, isLoading: isProductDetailsQueryLoading } =
    useProductDetailsControllerGetByProductId(
      {
        pathParams: {
          productId: props.productCatalogItem?.id as string,
        },
      },
      {
        enabled: props.open && !!props.productCatalogItem?.id,
      }
    );

  const getVariantOptionsWithDefaultValues = (
    variantOptions: ProductOption[]
  ) => {
    return variantOptions.map((option: ProductOption) => {
      if (option.key === 'Language') {
        return {
          ...option,
          values: option.values.sort((a, b) => (b === 'US English' ? 1 : -1)),
        };
      }
      return option;
    });
  };

  const images = productDetailsData?.images || [];
  const variantOptions = productDetailsData?.options
    ? getVariantOptionsWithDefaultValues(productDetailsData.options)
    : [];

  const isProtectionPlanRedundant = hasRedundantOption(
    PROTECTION_PLAN_ATTRIBUTE_NAME
  );

  const { data: productVariantData } =
    useProductVariantByAttributesControllerGetByAttributes(
      {
        pathParams: {
          productId: props.productCatalogItem?.id as string,
        },
        // ignoring typescript issues because it expects an "attributes" object but service throws error
        //@ts-ignore
        queryParams: {
          ...getValidVariantValues(watch()),
        },
      },
      {
        enabled: props.open && !!props.productCatalogItem?.id,
      }
    );

  const { data: productCategoryData } = useProductCategoryControllerRetrieve(
    {
      pathParams: {
        id: props.productCatalogItem?.productCategoryId as string,
      },
    },
    {
      enabled: !!props.productCatalogItem?.productCategoryId,
    }
  );
  const allFieldValuesNonEmpty = allWatchedFormValuesNonEmpty(watch());

  React.useEffect(() => {
    if (!allFieldValuesNonEmpty || !productVariantData) return;
    trigger();
  }, [allFieldValuesNonEmpty, productVariantData]);

  //Default to variant price, then price from catalog, then 'Retail Price' if neither have prices
  const priceOfProduct =
    productVariantData?.price ||
    props.productCatalogItem?.price ||
    RETAIL_PRICE;

  const cleanHTMLDescription = sanitize(
    props.productCatalogItem?.description || 'N/A',
    {
      USE_PROFILES: { html: true },
      FORBID_ATTR: ['style'],
    }
  );

  const descriptionInnerHTML = { __html: cleanHTMLDescription };

  const showInsuranceDropDownForRetrievals = isProductCategory(
    productCategoryData,
    ProductCategory.RETRIEVAL
  );

  const showLaptopProtectionPlanSection =
    isProductCategory(productCategoryData, ProductCategory.LAPTOPS) &&
    !isProtectionPlanRedundant(variantOptions);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempNumber = Number(event.target.value);
    const isBadEntry =
      Number.isNaN(tempNumber) ||
      tempNumber < 1 ||
      !Number.isInteger(tempNumber);

    if (isBadEntry) {
      setProductVariantQuantity(1);
      return;
    }

    setProductVariantQuantity(tempNumber);
  };

  const getAddButtonText = (): string =>
    props?.addProductButtonText ?? 'Add to order';

  const onClickCloseModal = () => {
    props.handleClose();
  };

  const showOnSuccessSnackbar = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Successfully added to your cart.',
        verticalOrigin: 'bottom',
        horizontalOrigin: 'right',
        hideNoticeDelay: 5000,
      })
    );
  };

  const closeModalSuccessfully = () => {
    executeNowThenWait(
      () => {
        props.handleClose();
      },
      showOnSuccessSnackbar,
      500
    );
  };

  const onClickSubmit = () => {
    const incomingPlan = watch().protectionPlan ?? '';
    const derivedProtectionPlan = [NO_PROTECTION_PLAN, ''].some(
      (term: string) => term === incomingPlan
    )
      ? ''
      : incomingPlan;
    const quantity = productVariantQuantity;

    props.addProductVariantToCart({
      productVariantId: productVariantData?.id as string,
      productVariantPrice: productVariantData?.price as string,
      productName: props.productCatalogItem?.name as string,
      productCategoryId: props.productCatalogItem?.productCategoryId as string,
      quantity,
      variantValues: {
        ...getValidVariantValues(watch()),
      },
      insuranceAmount: watch().insuranceAmount,
      insuranceCharge: RETRIEVAL_PER_ITEM_CHARGE[watch().insuranceAmount] ?? 0,
      protectionPlan: derivedProtectionPlan,
      destinationAddress: {
        //TODO - remove these hardcoded values - Need to pass these values due to DTO updating this type
        fullName: '',
        phoneNumber: '',
        email: '',
        streetAddress1: watch().streetAddressOne,
        streetAddress2: watch().streetAddressTwo,
        city: watch().city,
        state: watch().state,
        principalRegion: watch().region,
        country: watch().country,
        zipCode: watch().postalCode,
      },
    });
    closeModalSuccessfully();
  };

  // TODO Once Retrieval copy is implemented - we will always show the accordion */
  const showDisclaimerAccordion =
    showDisclaimerAccordionSLA(productCategoryData);

  if (!props.productCatalogItem) return <></>;

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={onClickCloseModal}
      TransitionComponent={PdpSlideUpTransition}
    >
      <form onSubmit={handleSubmit(onClickSubmit)}>
        <AppBar sx={{ position: 'sticky' }} color="secondary">
          <Toolbar>
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onClickCloseModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h5" sx={{ fontWeight: '600' }} pl="5px">
                  {props.productCatalogItem.name}
                </Typography>
              </Stack>
              {props.showAddToOrderButton ? (
                <Button
                  gaContext={{
                    navigates_to: 'N/A',
                    textCopy: getAddButtonText(),
                    purpose: 'Adds Item To Cart',
                  }}
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={
                    isProductDetailsQueryLoading ||
                    !productVariantData ||
                    !isValid
                  }
                  sx={styles.AddToOrderButtonSx}
                >
                  <Typography 
                    variant="button"
                  >
                    {getAddButtonText()}
                  </Typography>
                </Button>
              ) : null}
            </Stack>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <SwipeableImagesView images={images} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Stack sx={styles.ProductDetailsContainerSx}>
              <Stack width="100%">
                <Typography variant="h5">
                  {props.productCatalogItem?.name}
                </Typography>
                <Typography color={theme.palette.colors.onyxWN}>
                  {fixProductDetailsPrice(priceOfProduct)}
                </Typography>
                <Divider sx={styles.DividerSx} />
                {props.showAddToOrderButton ? (
                  <Stack mt="16px" mb="20px">
                    <TextField
                      variant="outlined"
                      type="number"
                      value={productVariantQuantity}
                      onChange={handleQuantityChange}
                      sx={styles.ProductQuantityInputSx}
                    />
                  </Stack>
                ) : (
                  <> </>
                )}
                <Grid container columnSpacing={7} rowSpacing={2} mt={1} mb={3}>
                  {variantOptions.map((variantOption) => (
                    <FieldInputWrapper key={variantOption.key}>
                      <Typography
                        variant="body2"
                        color={theme.palette.colors.grayWN}
                      >
                        {variantOption.key} *
                      </Typography>
                      <Select
                        variant="filled"
                        id={variantOption.key}
                        defaultValue={variantOption.values[0]}
                        sx={{
                          height: '34px',
                          width: '100%',
                        }}
                        {...register(`${variantOption.key}`, {
                          required: true,
                        })}
                      >
                        {variantOption.values.map(
                          (value) =>
                            value && (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FieldInputWrapper>
                  ))}
                  {showInsuranceDropDownForRetrievals ? (
                    <RetrievalInsuranceSelection
                      register={register}
                      watch={watch}
                    />
                  ) : null}
                  {showLaptopProtectionPlanSection ? (
                    <LaptopProtectionPlan register={register} />
                  ) : null}
                </Grid>
                {showDisclaimerAccordion ? (
                  <Box width="90%" pb="1rem">
                    <DisclaimerSLA />
                  </Box>
                ) : null}
                <Typography
                  variant="body1"
                  color="#122023"
                  dangerouslySetInnerHTML={descriptionInnerHTML}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default ProductDetailsModal;
