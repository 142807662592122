import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { FETCH_MORE_STATUS_HISTORY_BTN_HEIGHT } from 'pages/OrderDetails/constants';

export const OrderStatusHistoryStackSx: SxProps = {
  gap: '4px',
  position: 'relative',
  width: '100%',
};

export const getOrderStatusHistoryScrollBoxSx = (canFetchMore: boolean): SxProps => {
  const scrollBoxHeight = canFetchMore 
    ? `calc(75vh - calc(220px + ${FETCH_MORE_STATUS_HISTORY_BTN_HEIGHT}))` : 'calc(75vh - 220px)';

  return {
    height: scrollBoxHeight,
    overflowY: 'scroll',
    width: '100%',
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  };
};

export const FetchMoreOrderHistorySx: SxProps = {
  color: theme.palette.colors.woodSmoke,
  height: FETCH_MORE_STATUS_HISTORY_BTN_HEIGHT,
  maxHeight: '24px',
  maxWidth: '100px',
  mx: 0,
  justifyContent: 'flex-start',
  px: 0,
  textDecoration: 'underline',
};
