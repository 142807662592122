import React from 'react';
import { OrderManager } from 'types';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { getSearchOrderManagers } from 'api/apollo/queries/v1'
import { SEARCH_ORDER_MANAGERS_OPTION_OUTPUT } from './constants';

const useGetSearchOrderManagers = () => {
  const { data: orderManagerData, loading: loadingManagers } = useSafeQuery(
    getSearchOrderManagers(SEARCH_ORDER_MANAGERS_OPTION_OUTPUT),
    {
      variables: {
        orderAsc: 'role',
      }
    }
  );

  const orderManagers = orderManagerData?.searchOrderManagers?.orderManagers || [];

  const memoManagersByRole = React.useMemo(() => {
    if (loadingManagers || !orderManagers.length) return {};

    return orderManagers
      .reduce((acc: Record<string, OrderManager[]>, nextOM: OrderManager) => {
        const { role } = nextOM;

        if (typeof role !== 'string') {
          return acc;
        }

        if (role in acc) {
          acc[role].push(nextOM);
        } else {
          acc[role] = [nextOM];
        }

        return acc;
      }, {});
  }, [orderManagers.length, loadingManagers]);

  return { managersByRole: memoManagersByRole, loadingManagers };
}

export default useGetSearchOrderManagers;
