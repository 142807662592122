import { OrderDetailsPanelProps } from 'pages/OrderDetails/components/OrderDetailsPanel/types';
import { formatDate, capitalize } from 'services';
import { CountryEnum, StateEnum } from 'global-constants';
import { OrderDetailsTab } from './constants';

export const getOrderDetailsPanelProps = (
  orderDetailsData: Record<string, any>,
  onOrderItemsClick: () => void
): OrderDetailsPanelProps => {
  const {
    orderNumber,
    orderType = {},
    createdAt: orderDate = '-',
    orderItems = [{}],
    recipient = {},
    finalDestination = '-',
    estimatedDeliveryDate = '-',
    status: orderStatus,
    shippingType,
  } = orderDetailsData;

  const address = recipient?.address;
  const firstName = `${recipient?.firstName ?? ''}`;
  const lastName = `${recipient?.lastName ?? ''}`;
  let fullName = `${firstName} ${lastName}`.trim();
  fullName = fullName.length > 0 ? fullName : '-';
  const principality =
    address?.principalRegion ||
    (StateEnum[address?.state as keyof typeof StateEnum] ??
      address?.state ??
      '');
  const streetLine2 = address?.streetAddress2
    ? `${address?.streetAddress2}\n`
    : '';
  const recipientAddress = address
    ? `${address.streetAddress1}\n${streetLine2}${
        address.city
      }, ${principality} ${address.zipCode}\n${
        CountryEnum[address.country as keyof typeof CountryEnum] ??
        address.country
      }`
    : '-';

  const shipping = shippingType?.name ?? 'Standard';

  return {
    orderNumber,
    orderType: orderType?.name ?? '-',
    orderDate: formatDate(orderDate),
    source: orderItems[0]?.source ? capitalize(orderItems[0]?.source) : '-',
    estimatedDeliveryDate:
      estimatedDeliveryDate.length > 1
        ? formatDate(estimatedDeliveryDate)
        : estimatedDeliveryDate,
    orderStatus,
    recipient: fullName,
    recipientEmail: recipient?.email ?? '-',
    finalDestination,
    shipping,
    recipientAddress,
    clickEvent: onOrderItemsClick,
  };
};

export const getInitialOrderDetailsTab = (
  input?: string | null
): OrderDetailsTab => {
  if (!input) {
    return OrderDetailsTab.ORDER_DETAILS;
  }

  const cleanInput = input.trim().toLowerCase();

  switch (cleanInput) {
    case 'orderitems':
      return OrderDetailsTab.ORDER_ITEMS;
    case 'orderdetails':
      return OrderDetailsTab.ORDER_DETAILS;
    default:
      return OrderDetailsTab.ORDER_DETAILS;
  }
};
