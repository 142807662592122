/* eslint-disable no-underscore-dangle */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Stack, TextField, Box, Divider, Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AssetDetailsTab } from './enums';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomNotifications } from 'components/GlobalToastNotification/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssetDetailsStatusPill from 'components/AssetStatusPill';
import { AssetStatusPillViews } from 'components/AssetStatusPill/constants';
import LoadingIndictor from 'components/LoadingIndicator';
import TextIconButton from 'components/TextIconButton';
import { NavigationContext } from 'context/NavigationContext';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import { Asset } from 'types';
import DeviceAssignedConfirmationModal from 'pages/Assets/components/DevicesDatagrid/components/DeviceAssignedConfirmationModal';
import EditDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/EditDeviceModal';
import ProcessDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/ProcessDeviceModal';
import RecycleDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/RecycleDeviceModal';
import RepairDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/RepairDeviceModal';
import ResellDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/ResellDeviceModal';
import DeleteDeviceModal from 'pages/Assets/components/DevicesDatagrid/components/DeleteDeviceModal';
import { useGetDeviceActionsEnablement } from 'pages/Assets/components/DevicesDatagrid/hooks/useGetDeviceActionsEnablement';
import AssetActions from 'pages/Assets/components/DevicesDatagrid/components/AssetActions';
import { EditDateInlineFormModes } from 'components/EditDateInlineForm/constants';
import { RETRIEVE_ASSET } from './queries';
import * as AssetDetailStyles from './styles';
import {
  AssetStatusSelfDescriptive,
  MM_DD_YYYY_SLASH_FORMAT,
} from 'global-constants';
import {
  formatDate,
  executeNowThenWait,
  dateUTCStringToMediumDate,
  getEasternUSTime,
} from 'services';
import {
  AssetImageChip,
  AssetImagesModal,
  EditDateInlineForm,
} from 'components';
import { UPDATE_DETAILED_ASSET } from './mutations';
import {
  ASSET_ASSIGNEE_PERSONAL_EMAIL_TESTID,
  ASSET_ASSIGNEE_TESTID,
  ASSET_ASSIGNEE_WORK_EMAIL_TESTID,
  ASSET_DETAILS_TESTID,
  ASSET_FIELD_UPDATE_ORIGIN,
  ASSET_DETAILS_HEADER,
  ASSET_DETAILS_SUBHEADER,
  VIEW_ASSET_IMAGES,
} from './constants';
import AssetNote from './components/AssetNote';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import UnlockDeviceModal from '../Assets/components/DevicesDatagrid/components/unlock_device/UnlockDeviceModal';
import UnlockOrderSubmittedModal from '../Assets/components/DevicesDatagrid/components/unlock_device/UnlockOrderSubmittedModal';
import AssetStatusHistoryPanel from './asset_status_history/AssetStatusHistoryPanel';

const AssetDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);
  const [updateOrigin, setUpdateOrigin] = React.useState<string>('');
  const [pickedPurchaseDate, setPickedPurchaseDate] =
    React.useState<Moment | null>(null);
  const [purchaseDateMode, setPurchaseDateMode] =
    React.useState<EditDateInlineFormModes>(EditDateInlineFormModes.ADD);
  const [pickedWarrantyExpiration, setPickedWarrantyExpiration] =
    React.useState<Moment | null>(null);
  const [warrantyExpirationMode, setWarrantyExpirationMode] =
    React.useState<EditDateInlineFormModes>(EditDateInlineFormModes.ADD);
  const [purchaseDateFormKey, setPurchaseDateFormKey] =
    React.useState<string>('');
  const [warrantyExpirationFormKey, setWarrantyExpirationFormKey] =
    React.useState<string>('');

  const {
    OnFlagTrueWrapper: EmployeeEmailOptionsEnabledDisplay,
    OnFlagFalseWrapper: EmployeeEmailOptionsDisabledDisplay,
  } = useFeatureFlagService(FeatureFlagNames.EMPLOYEE_EMAIL_OPTIONS, {
    debugFlags: true,
  });

  const {
    OnFlagTrueWrapper: AssetStatusHistoryEnabled,
    isFlagOn: assetStatusHistoryShown,
  } = useFeatureFlagService(FeatureFlagNames.VISUALIZE_ASSET_STATUS_HISTORY, {
    debugFlags: true,
  });

  const {
    data,
    loading,
    refetch: refetchAsset,
  } = useSafeQuery(RETRIEVE_ASSET, {
    variables: {
      assetId: id,
    },
    fetchPolicy: 'network-only',
  });

  const displayCompletionNotice = () => {
    const updateNotice =
      CustomNotifications.fieldUpdatedSuccessfully(updateOrigin);

    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: updateNotice,
        verticalOrigin: 'bottom',
        horizontalOrigin: 'right',
      }),
    );
  };

  const [updateDetailAsset, { loading: isLoadingUpdate }] = useSafeMutation(
    UPDATE_DETAILED_ASSET,
    {
      onCompleted: (data) => {
        if (!data) return;
        displayCompletionNotice();
      },
    },
  );

  const styles = AssetDetailStyles;
  const device = data?.getAsset as Asset;

  const deviceActionsEnablement = useGetDeviceActionsEnablement();

  const [assetDetailsPanelTab, setAssetDetailsPanelTab] =
    React.useState<AssetDetailsTab>(AssetDetailsTab.DETAILS);

  const handleOnTabChange = (
    _event: React.SyntheticEvent,
    value: AssetDetailsTab,
  ) => {
    setAssetDetailsPanelTab(value);
  };

  React.useEffect(() => {
    if (device) deviceActionsEnablement.enableActionsByDevice(device);
  }, [device]);

  // User Org & Purchaser info
  const userOrganization = useGetUserOrganization();
  const globalPurchaser = useGetGlobalPurchaser();
  const organizationId = userOrganization?.id || '';
  const purchaserId = globalPurchaser?.id || '';

  //Modal state
  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal,
  );
  const showEditDeviceModal = currentModal === GlobalModals.EDIT_DEVICE;
  const showProcessDeviceModal = currentModal === GlobalModals.PROCESS_DEVICE;
  const showRecycleDeviceModal = currentModal === GlobalModals.RECYCLE_DEVICE;
  const showRepairDeviceModal = currentModal === GlobalModals.REPAIR_DEVICE;
  const showResellDeviceModal = currentModal === GlobalModals.RESELL_DEVICE;
  const showDeleteDeviceModal = currentModal === GlobalModals.DELETE_DEVICE;
  const showUnlockDeviceModal = currentModal === GlobalModals.UNLOCK_DEVICE;

  const showAssignedDeviceConfirmationModal =
    currentModal === GlobalModals.DEVICE_ASSIGNED_CONFIRMATION;
  const showAssetImagesModal = currentModal === GlobalModals.ASSET_IMAGES;
  const assetHasImages =
    !!device && device.assetImages && device.assetImages?.length > 0;

  const handleModalOnClose = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.NONE));
  };

  const handleShowAssetImagesModal = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.ASSET_IMAGES));
  };

  const handleEditPurchaseDate = () => {
    setPurchaseDateMode(() => EditDateInlineFormModes.EDIT);
  };

  const handleAddPurchaseDate = () => {
    setPurchaseDateMode(() => EditDateInlineFormModes.EDIT);
  };

  const handlePurchaseDateCancelEdit = () => {
    setPurchaseDateMode(() =>
      device?.purchaseDate
        ? EditDateInlineFormModes.VIEW
        : EditDateInlineFormModes.ADD,
    );

    setPickedPurchaseDate(() => moment(device?.purchaseDate ?? null));
  };

  const handlePurchaseDateChange = (date: Moment | null) => {
    setUpdateOrigin(ASSET_FIELD_UPDATE_ORIGIN.purchaseDate);
    setPickedPurchaseDate(date);
  };

  const handleSavePurchaseDate = () => {
    const date = pickedPurchaseDate?.toISOString();

    updateDetailAsset({
      variables: {
        id: device?.id,
        ...(date && { purchaseDate: date }),
      },
      update(cache) {
        cache.modify({
          optimistic: true,
          fields: {
            getAsset(data: any) {
              const asset = data.asset || {};

              return {
                ...asset,
                purchaseDate: date,
              };
            },
          },
        });
      },
    }).then(() => {
      setPickedWarrantyExpiration(null);
      setWarrantyExpirationFormKey(() => nanoid());
    });
  };

  const handleWarrantyExpirationEdit = () => {
    setWarrantyExpirationMode(() => EditDateInlineFormModes.EDIT);
  };

  const handleAddWarrantyExpiration = () => {
    setWarrantyExpirationMode(() => EditDateInlineFormModes.EDIT);
  };

  const handleEditWarrantyExpirationCancel = () => {
    setWarrantyExpirationMode(() =>
      device?.warrantyExpiration
        ? EditDateInlineFormModes.VIEW
        : EditDateInlineFormModes.ADD,
    );

    setPickedWarrantyExpiration(() =>
      moment(device?.warrantyExpiration ?? null),
    );
  };

  const getWarrantyExpirationDate = (): string | undefined =>
    device?.warrantyExpiration ?? undefined;

  const getPurchaseDate = (): string | undefined =>
    device?.purchaseDate ?? undefined;

  const handleWarrantyExpirationChange = (date: Moment | null) => {
    setUpdateOrigin(ASSET_FIELD_UPDATE_ORIGIN.warrantyExpiration);
    setPickedWarrantyExpiration(date);
  };

  const handleSaveWarrantyExpiration = () => {
    const warrantyExpirationDate = pickedWarrantyExpiration?.toISOString();

    updateDetailAsset({
      variables: {
        id: device?.id,
        ...(warrantyExpirationDate && {
          warrantyExpiration: warrantyExpirationDate,
        }),
      },
      update(cache) {
        cache.modify({
          optimistic: true,
          fields: {
            getAsset(data: any) {
              const asset = data.asset || {};

              return {
                ...asset,
                warrantyExpiration: warrantyExpirationDate,
              };
            },
          },
        });
      },
    }).then(() => {
      setPickedPurchaseDate(null);
      setPurchaseDateFormKey(() => nanoid());
    });
  };

  React.useEffect(() => {
    if (!device?.purchaseDate) return;
    const passValue = device?.purchaseDate ?? null;
    setPickedPurchaseDate(() =>
      passValue !== null ? moment(passValue) : null,
    );
    setPurchaseDateMode(() => EditDateInlineFormModes.VIEW);
  }, [device?.purchaseDate]);

  React.useEffect(() => {
    if (!device?.warrantyExpiration) return;
    const passValue = device?.warrantyExpiration ?? null;
    setPickedWarrantyExpiration(() =>
      passValue !== null ? moment(passValue) : null,
    );
    setWarrantyExpirationMode(() => EditDateInlineFormModes.VIEW);
  }, [device?.warrantyExpiration]);

  const handleBackButtonClick = () => {
    navigate(navigator.pathToAssetsDevices());
  };

  const [assetDetailsCustomerNote, setAssetDetailsCustomerNote] =
    React.useState('');

  React.useEffect(() => {
    setAssetDetailsCustomerNote(device?.customerNote ?? '');
  }, [device?.customerNote]); //no guard clause since no change

  const submitCustomerNote = (newCustomerNote: String) => {
    updateDetailAsset({
      variables: {
        id: device?.id,
        customerNote: newCustomerNote,
      },
      update(cache) {
        cache.modify({
          optimistic: true,
          fields: {
            getAsset(data: any) {
              const asset = data.asset || {};

              return {
                ...asset,
                customerNote: newCustomerNote,
              };
            },
          },
        });
      },
    });
  };

  const lastUpdatedAt = device?.updatedAt
    ? `${dateUTCStringToMediumDate(device?.updatedAt)}, ${getEasternUSTime(
        device?.updatedAt,
      )}`
    : '';

  const [showUnlockOrderSubmittedModal, setShowUnlockOrderSubmittedModal] =
    React.useState<boolean>(false);

  return (
    <>
      <LoadingIndictor loading={loading} />
      <Container
        sx={styles.MainContainerDivSx}
        data-testid={ASSET_DETAILS_TESTID}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt="10"
        >
          <TextIconButton
            text="Back to Devices"
            ariaLabel="Navigate back to Devices view"
            icon={<ArrowBackIcon />}
            onClick={handleBackButtonClick}
          />
          <AssetActions
            context="details"
            device={device}
            enableProcessDeviceAction={
              deviceActionsEnablement.enableProcessDeviceAction
            }
            enableRepairDeviceAction={
              deviceActionsEnablement.enableRepairDeviceAction
            }
            enableRecycleDeviceAction={
              deviceActionsEnablement.enableRecycleDeviceAction
            }
            enableResellDeviceAction={
              deviceActionsEnablement.enableResellDeviceAction
            }
            enableAllocateDeviceAction={
              deviceActionsEnablement.enableAllocateDeviceAction
            }
            enableEditDeviceAction={true}
            enableDeleteDeviceAction={true}
            enableUnlockDeviceAction={
              deviceActionsEnablement.enableUnlockDeviceAction
            }
          />
        </Stack>
        <Typography variant="h2">Asset details</Typography>
        <Stack sx={styles.ContentDivSx} alignItems="flex-start">
          <Typography variant="h6">
            {device?.make} {device?.model}
          </Typography>

          <TabContext value={assetDetailsPanelTab}>
            <TabList sx={styles.TabsSx} onChange={handleOnTabChange}>
              <Tab
                label={AssetDetailsTab.DETAILS}
                value={AssetDetailsTab.DETAILS}
              />
              {assetStatusHistoryShown() ? (
                <Tab
                  label={AssetDetailsTab.ASSET_STATUS_HISTORY}
                  value={AssetDetailsTab.ASSET_STATUS_HISTORY}
                />
              ) : (
                <></>
              )}
              <Tab
                label={AssetDetailsTab.ASSET_NOTE}
                value={AssetDetailsTab.ASSET_NOTE}
              />
            </TabList>
            <Divider sx={styles.TitleDividerSx} />
            {/* END of TabList */}
            <TabPanel
              value={AssetDetailsTab.DETAILS}
              sx={styles.AssetDetailsPanelSx}
            >
              <Stack sx={styles.AssetInfoStackSx}>
                <Typography variant="subtitle2">
                  {ASSET_DETAILS_HEADER.GENERAL}
                </Typography>
                <Grid container sx={styles.AssetInfoGridSx}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.ASSIGNEE}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontFamily="inter"
                      fontWeight="600"
                      data-testid={ASSET_ASSIGNEE_TESTID}
                    >
                      {device?.assignee?.id && (
                        <Link
                          href={navigator.constructPathToEmployeeDetails(
                            device?.assignee?.id,
                          )}
                          target="_blank"
                          rel="noopener"
                          color="colors.black"
                        >
                          {device?.assignee?.firstName}{' '}
                          {device?.assignee?.lastName}
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                  <EmployeeEmailOptionsEnabledDisplay>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="overline" color="colors.gray60">
                        {ASSET_DETAILS_SUBHEADER.WORK_EMAIL}
                      </Typography>
                      <Typography
                        variant="body1"
                        data-testid={ASSET_ASSIGNEE_WORK_EMAIL_TESTID}
                      >
                        {device?.assignee?.workEmail}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="overline" color="colors.gray60">
                        {ASSET_DETAILS_SUBHEADER.PERSONAL_EMAIL}
                      </Typography>
                      <Typography
                        variant="body1"
                        data-testid={ASSET_ASSIGNEE_PERSONAL_EMAIL_TESTID}
                      >
                        {device?.assignee?.personalEmail}
                      </Typography>
                    </Grid>
                  </EmployeeEmailOptionsEnabledDisplay>
                  <EmployeeEmailOptionsDisabledDisplay>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="overline" color="colors.gray60">
                        {ASSET_DETAILS_SUBHEADER.CONTACT_EMAIL}
                      </Typography>
                      <Typography variant="body1">
                        {device?.assignee?.email}
                      </Typography>
                    </Grid>
                  </EmployeeEmailOptionsDisabledDisplay>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.DEPOT_LOCATION}
                    </Typography>
                    <Typography variant="body1">
                      {device?.depot?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={styles.AssetInfoStackSx}>
                <Typography variant="subtitle2">
                  {ASSET_DETAILS_HEADER.DEVICE_SPECS}
                </Typography>
                <Grid container sx={styles.AssetInfoGridSx}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.SERIAL_NUMBER}
                    </Typography>
                    <Typography variant="body1">
                      {device?.serialNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.TYPE}
                    </Typography>
                    <Typography variant="body1">
                      {device?.assetType?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.CONDITION}
                    </Typography>
                    <Stack direction="row" alignItems="center" gap="6px">
                      <Typography variant="body1">
                        {device?.cosmeticCondition}{' '}
                      </Typography>
                      {assetHasImages ? (
                        <>
                          <AssetImageChip assetImages={device?.assetImages} />
                          <Link
                            color="secondary"
                            sx={styles.ViewImageLink}
                            onClick={handleShowAssetImagesModal}
                          >
                            {VIEW_ASSET_IMAGES}
                          </Link>
                        </>
                      ) : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.MAKE}
                    </Typography>
                    <Typography variant="body1">{device?.make}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.MODEL}
                    </Typography>
                    <Typography variant="body1">{device?.model}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.DEVICE_ISSUE}
                    </Typography>
                    <Typography variant="body1">
                      {device?.deviceAssessment}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.COLOR}
                    </Typography>
                    <Typography variant="body1">{device?.color}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.DISPLAY_SIZE}
                    </Typography>
                    <Typography variant="body1">
                      {device?.displaySize}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.MEMORY}
                    </Typography>
                    <Typography variant="body1">{device?.memory}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.STORAGE}
                    </Typography>
                    <Typography variant="body1">{device?.storage}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.PROCESSOR}
                    </Typography>
                    <Typography variant="body1">{device?.processor}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.KEYBOARD_CONFIGURATION}
                    </Typography>
                    <Typography variant="body1">{device?.keyboard}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ position: 'relative' }}
                  >
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.PURCHASE_DATE}
                      <span style={styles.PurchaseDateInfoSpanStyle}>
                        <Tooltip
                          title="Purchase date is needed to track the warranty period of the devices, you can add or edit the date"
                          placement="right"
                          arrow
                        >
                          <InfoOutlinedIcon
                            sx={styles.PurchaseDateInfoIconSx}
                            fontSize="small"
                          />
                        </Tooltip>
                      </span>
                    </Typography>
                    <EditDateInlineForm
                      key={purchaseDateFormKey}
                      formMode={purchaseDateMode}
                      dateValue={getPurchaseDate()}
                      datePickerValue={pickedPurchaseDate}
                      handleCancelClick={handlePurchaseDateCancelEdit}
                      handleEditClick={handleEditPurchaseDate}
                      handleAddClick={handleAddPurchaseDate}
                      handleSaveClick={handleSavePurchaseDate}
                      isSaveLoading={isLoadingUpdate}
                      isVerified={device?.isPurchaseDateVerified ?? false}
                      disableSaveButton={!pickedPurchaseDate}
                      onDateChange={handlePurchaseDateChange}
                      idBaseName="asset-details-purchase-date"
                      editTitleField="Purchase Date"
                      addButtonName="Add purchase date"
                      disableFutureDates
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ position: 'relative' }}
                  >
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.WARRANTY_EXPIRATION}
                      <span style={styles.PurchaseDateInfoSpanStyle}>
                        <Tooltip
                          title="Warranty expiration date is needed to confirm the end date of the warranty period"
                          placement="right"
                          arrow
                        >
                          <InfoOutlinedIcon
                            sx={styles.PurchaseDateInfoIconSx}
                            fontSize="small"
                          />
                        </Tooltip>
                      </span>
                    </Typography>
                    <EditDateInlineForm
                      key={warrantyExpirationFormKey}
                      formMode={warrantyExpirationMode}
                      dateValue={getWarrantyExpirationDate()}
                      datePickerValue={pickedWarrantyExpiration}
                      handleCancelClick={handleEditWarrantyExpirationCancel}
                      handleEditClick={handleWarrantyExpirationEdit}
                      handleAddClick={handleAddWarrantyExpiration}
                      handleSaveClick={handleSaveWarrantyExpiration}
                      onDateChange={handleWarrantyExpirationChange}
                      isSaveLoading={isLoadingUpdate}
                      isVerified={device?.isWarrantyExpirationVerified ?? false}
                      disableSaveButton={!pickedWarrantyExpiration}
                      idBaseName="asset-details-warranty-expiration"
                      editTitleField="Warranty Expiration"
                      addButtonName="Add warranty expiration"
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={styles.AssetInfoStackSx}>
                <Typography variant="subtitle2">
                  {ASSET_DETAILS_HEADER.ALLWHERE_INFO}
                </Typography>
                <Grid container sx={styles.AssetInfoGridSx}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.ASSET_NUMBER}
                    </Typography>
                    <Typography variant="body1">
                      {device?.assetNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="overline"
                      color="colors.gray60"
                      display="block"
                    >
                      {ASSET_DETAILS_SUBHEADER.STATUS}
                    </Typography>
                    <AssetDetailsStatusPill
                      status={
                        device?.status as unknown as AssetStatusSelfDescriptive
                      }
                      pillView={AssetStatusPillViews.TABLE}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="overline"
                      color="colors.gray60"
                      display="block"
                    >
                      {ASSET_DETAILS_SUBHEADER.LAST_UPDATED}
                    </Typography>
                    <Typography variant="body1">{lastUpdatedAt}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.LAST_CHECK_IN_DATE}
                    </Typography>
                    <Typography variant="body1">
                      {device?.inventoryLog?.dateOfLastCheckIn
                        ? formatDate(
                            device?.inventoryLog?.dateOfLastCheckIn,
                            MM_DD_YYYY_SLASH_FORMAT,
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.LAST_CHECKOUT_DATE}
                    </Typography>
                    <Typography variant="body1">
                      {device?.inventoryLog?.dateOfLastCheckOut
                        ? formatDate(
                            device?.inventoryLog?.dateOfLastCheckOut,
                            MM_DD_YYYY_SLASH_FORMAT,
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="overline" color="colors.gray60">
                      {ASSET_DETAILS_SUBHEADER.ORDER_NUMBER_HISTORY}
                    </Typography>
                    {device?.associatedOrders?.map((order: any) => (
                      <Typography
                        key={order.id}
                        variant="body1"
                        fontFamily="inter"
                        fontWeight="600"
                        pb=".5rem"
                      >
                        <Link
                          href={navigator.constructSecurePathToOrderDetails(
                            order.id,
                            organizationId,
                          )}
                          target="_blank"
                          rel="noopener"
                          color="colors.black"
                        >
                          {order.orderNumber}
                        </Link>
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Stack>
            </TabPanel>
            <AssetStatusHistoryEnabled>
              <TabPanel
                value={AssetDetailsTab.ASSET_STATUS_HISTORY}
                sx={styles.AssetDetailsOtherPanelSx}
              >
                {device ? (
                  <AssetStatusHistoryPanel
                    assetId={id ?? ''}
                    createdAt={device.updatedAt}
                    currentStatus={device.status}
                  />
                ) : null}
              </TabPanel>
            </AssetStatusHistoryEnabled>
            <TabPanel
              value={AssetDetailsTab.ASSET_NOTE}
              sx={styles.AssetDetailsOtherPanelSx}
            >
              <AssetNote
                customerNote={assetDetailsCustomerNote}
                originalCustomerNote={device?.customerNote ?? ''}
                submitCustomerNote={submitCustomerNote}
                setCustomerNote={setAssetDetailsCustomerNote}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </Container>
      <EditDeviceModal
        device={device}
        open={showEditDeviceModal}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchAsset}
        enableActionsByDevice={deviceActionsEnablement.enableActionsByDevice}
      />
      <ProcessDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={device}
        open={showProcessDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchAsset}
      />
      <RecycleDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={device}
        open={showRecycleDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchAsset}
      />
      <RepairDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={device}
        open={showRepairDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchAsset}
      />
      <ResellDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={device}
        open={showResellDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchAsset}
      />
      <DeleteDeviceModal
        navigateToTableOnDelete
        open={showDeleteDeviceModal}
        assetId={device?.id ?? undefined}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchAsset}
      />
      <DeviceAssignedConfirmationModal
        open={showAssignedDeviceConfirmationModal}
        handleClose={handleModalOnClose}
      />
      <AssetImagesModal
        asset={device}
        open={showAssetImagesModal}
        handleClose={handleModalOnClose}
      />
      <UnlockDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={device}
        open={showUnlockDeviceModal}
        handleClose={handleModalOnClose}
        setShowUnlockOrderSubmittedModal={setShowUnlockOrderSubmittedModal}
        refetchSearchAssets={refetchAsset}
        enableActionsByDevice={deviceActionsEnablement.enableActionsByDevice}
      />
      <UnlockOrderSubmittedModal
        open={showUnlockOrderSubmittedModal}
        setShowUnlockOrderSubmittedModal={setShowUnlockOrderSubmittedModal}
      />
    </>
  );
};

export default AssetDetails;
