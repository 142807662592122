export const ORDER_STATUS_HISTORY_BOX_CONTAINER_ID =
  'order-details-order-status-history-box-container';
export const ORDER_STATUS_HISTORY_QUERY_OUTPUT = `
  count
  items {
    fromStatus: from
    toStatus: to
    createdAt
    recordId: orderId
  } 
`;

// hooks constants
export const DEFAULT_ITEMS_COUNT_OFFSET = 100;
export const REVEAL_ITEMS_DEFAULT = 10;
