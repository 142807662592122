import React from 'react';
import * as MaterialIcons from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { AssetDetailStatus } from 'pages/AssetDetails/constants';
import BackBoxIcon from 'components/Icons/order_details/order_status_history/BackBoxIcon';
import SmoothRecycleIcon from 'components/Icons/order_details/order_status_history/SmoothRecycleIcon';

export const ASSET_STATUS_ICON_MAP: Record<string, typeof SvgIcon> = {
  [AssetDetailStatus.IN_INVENTORY]: MaterialIcons.HomeWorkOutlined,

  [AssetDetailStatus.ALLOCATED]: MaterialIcons.HomeOutlined,
  [AssetDetailStatus.TRANSFERRED]: MaterialIcons.DomainAdd,
  [AssetDetailStatus.INTAKE]: MaterialIcons.WarehouseOutlined,

  [AssetDetailStatus.RECYCLED]: SmoothRecycleIcon,
  [AssetDetailStatus.SOLD]: MaterialIcons.AttachMoney,
  [AssetDetailStatus.DONATED]: MaterialIcons.FavoriteBorderOutlined,
  [AssetDetailStatus.LOST]: MaterialIcons.LocationOffOutlined,

  [AssetDetailStatus.PENDING_ALLOCATION]: MaterialIcons.AccessTimeOutlined,
  [AssetDetailStatus.PENDING_CERTIFICATION]: MaterialIcons.AccessTimeOutlined,
  [AssetDetailStatus.PENDING_RECYCLE]: SmoothRecycleIcon,
  [AssetDetailStatus.PENDING_REPAIR]: MaterialIcons.BuildOutlined,
  [AssetDetailStatus.PENDING_SALE]: MaterialIcons.AttachMoney,
  [AssetDetailStatus.PROCESSING_DEVICE]: MaterialIcons.SettingsOutlined,
  [AssetDetailStatus.QUOTE_IN_PROGRESS]: MaterialIcons.LaptopOutlined,
  [AssetDetailStatus.PENDING_RETRIEVAL]: BackBoxIcon,
  [AssetDetailStatus.PENDING_WIPE]: MaterialIcons.CleaningServicesOutlined,

  [AssetDetailStatus.ACTIVATION_LOCKED]: MaterialIcons.LockOutlined,
  [AssetDetailStatus.RECOVERY_LOCKED]: MaterialIcons.LockOutlined,
  [AssetDetailStatus.HOLD]: MaterialIcons.PauseCircleOutlined,
  [AssetDetailStatus.MDM_LOCKED]: MaterialIcons.LockOutlined,
  [AssetDetailStatus.IN_INVENTORY_DEVICE_ISSUE]: MaterialIcons.HomeWorkOutlined,
  [AssetDetailStatus.PENDING_QUOTE_APPROVAL]: MaterialIcons.AccessTimeOutlined,
};

export const CUSTOM_SVG_STATUS: Record<string, boolean> = {
  [AssetDetailStatus.PENDING_RETRIEVAL]: true,
  [AssetDetailStatus.RECYCLED]: true,
  [AssetDetailStatus.PENDING_RECYCLE]: true,
};
