export const ASSET_STATUS_HISTORY_BOX_CONTAINER_ID =
  'asset-details-asset-status-history-box-container';

// hooks constants
export const DEFAULT_ITEMS_COUNT_OFFSET = 100;
export const REVEAL_ITEMS_DEFAULT = 10;
export const ASSET_STATUS_HISTORY_QUERY_OUTPUT = `
  count
  items {
    fromStatus: from
    toStatus: to
    createdAt
    recordId: assetId 
  } 
`;
