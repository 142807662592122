import Pill from 'components/Pill';
import { OrderStatusPillProps } from './types';
import { useGetOrderStatusConfig } from './hooks/useGetOrderStatusConfig';
import { Typography } from '@mui/material';
import { additionalTextSx } from './styles';

const OrderStatusPill = (props: OrderStatusPillProps) => {
  const showStatusName = props.showStatusName ?? false;
  const showClientStatus = props.showClientStatus ?? false;
  const showTooltip = props.showHoverText ?? true;

  const config = useGetOrderStatusConfig(props.status, {
    showStatusName,
    showClientStatus,
  }, props.clickEvent);

  return (
    <>
      <Pill
        size="small"
        toolTipArrow
        text={config.text}
        color={config.color}
        textColor={config.textColor}
        toolTipHoverText={showTooltip ? config.hoverText : undefined}
        toolTipPlacement={props.toolTipPlacement ?? 'right'}
        data-testid={props['data-testid']}
      />
      {props.showAdditionalText && config.additionalText && (
        <Typography sx={additionalTextSx}>{config.additionalText}</Typography>
      )}
    </>
  );
};

export default OrderStatusPill;
