/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import { Avatar } from 'components';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { Order, OrderManager } from 'types';
import {
  MANAGERS_ROLE_ID_FIELD_MAP,
  MANAGER_MODEL_FIELD_BY_ROLE,
} from 'pages/OrderManagement/constants';
import OrderManagerMenu from 'pages/OrderManagement/hooks/useGetOrderManagementColumns/components/OrderManagerMenu';
import {
  getOMHeaderStyles,
  AddOMButtonStyles,
  AddOMButtonIconStyles,
} from './styles';
import getBadgeColorsByOMRole from './utils';

const useGetManagersColumn = (
  mutate: any,
  managersByRole: Record<string, OrderManager[]>,
  omRole: string
): GridColDef => {
  const omForeignKey = MANAGERS_ROLE_ID_FIELD_MAP[omRole] || '';
  const omModelField = MANAGER_MODEL_FIELD_BY_ROLE[omRole] || '';
  const dispatch = useDispatch();

  if (!omForeignKey || !omModelField) {
    console.error(
      `Order Manager role as entered is not valid: ${omRole}. Please enter a valid role (ORDER_MANAGEMENT, PROCUREMENT, ASSET_MANAGEMENT, OTHER).`
    );
  }

  const { bgcolor, color } = getBadgeColorsByOMRole(omRole);

  const roleShortHand = omRole
    .split('_')
    .map((word) => word[0])
    .join('');

  return {
    field: omRole,
    headerName: roleShortHand,
    flex: roleShortHand.length > 1 ? 1.2 : 1,
    type: 'actions',
    align: 'left',
    headerAlign: 'left',
    sortable: true,
    filterable: false,
    minWidth: 80,
    maxWidth: 90,
    renderHeader: () => (
      <Typography sx={getOMHeaderStyles(roleShortHand)}>
        {roleShortHand}
      </Typography>
    ),
    //eslint-disable-next-line sonarjs/cognitive-complexity
    getActions(params: GridRowParams) {
      const orderManagers = managersByRole[omRole] || [];

      const [assignedOrderManager, setAssignedOrderManager] =
        React.useState<OrderManager | null>(params.row[omModelField] ?? null);

      const managerType = omRole.split('_')[0].toLowerCase();
      const capitalManagerType = capitalize(managerType);

      const tooltipText =
        assignedOrderManager === null
          ? `Assign ${managerType} manager`
          : `${capitalManagerType} manager: ${assignedOrderManager?.firstName} ${assignedOrderManager?.lastName}`;

      const [open, setOpen] = React.useState(false);
      const anchorRefAdd = React.useRef<HTMLButtonElement>(null);
      const anchorRefEdit = React.useRef<HTMLButtonElement>(null);

      const handleSelect = (newOrderManagerId: string) => {
        let orderManagerIdPayload =
          newOrderManagerId === assignedOrderManager?.id
            ? null
            : newOrderManagerId;
        if (orderManagerIdPayload) {
          if (orderManagerIdPayload === 'none') {
            orderManagerIdPayload = null;
          }
          mutate({
            variables: {
              id: params.row.id,
              [omForeignKey]: orderManagerIdPayload,
            },
            onCompleted() {
              const newOrderManager = orderManagers.find(
                (orderManager: OrderManager) =>
                  orderManager.id === orderManagerIdPayload
              );
              setAssignedOrderManager(newOrderManager ?? null);
              dispatch(
                notificationSlice.actions.setNotice({
                  showNotice: true,
                  noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
                })
              );
              setOpen(false);
            },
            update(cache: any, { data: updateOrder }: any) {
              cache.modify({
                optimistic: true,

                fields: {
                  v2FindAndCountOrders(data: any) {
                    const newOrderManager = orderManagers.find(
                      (orderManager: OrderManager) =>
                        orderManager.id === updateOrder[omForeignKey]
                    );
                    const orders = data.orders || [];
                    return orders.map((order: Order) => {
                      return order.id === updateOrder.id
                        ? {
                            ...order,
                            [omModelField]: orderManagerIdPayload ? newOrderManager : null,
                            [omForeignKey]: orderManagerIdPayload ?? null,
                          }
                        : order;
                    });
                  },
                },
              });
            },
          });
        }
      };

      const handleClick = (event: any) => {
        setOpen((prevOpen) => !prevOpen);
      };

      const prevOpen = React.useRef(open);
      React.useEffect(() => {
        prevOpen.current = open;
      }, [open]);

      if (!assignedOrderManager) {
        return [
          <GridActionsCellItem
            size="small"
            label="Assign Order Manager"
            disableRipple
            icon={
              <>
                <Tooltip title={tooltipText} placement="bottom" arrow>
                  <IconButton
                    ref={anchorRefAdd}
                    // size="small"
                    disableRipple
                    sx={AddOMButtonStyles}
                  >
                    <AddIcon color="secondary" sx={AddOMButtonIconStyles} />
                  </IconButton>
                </Tooltip>
                <OrderManagerMenu
                  open={open}
                  omRole={omRole}
                  anchorEl={anchorRefAdd}
                  assignedOrderManagerId={undefined}
                  orderManagers={orderManagers}
                  handleSelect={handleSelect}
                />
              </>
            }
            onClick={handleClick}
          />,
        ];
      }
      const orderManagerInitials = assignedOrderManager
        ? `${assignedOrderManager?.firstName[0]}${assignedOrderManager?.lastName[0]}`
        : '';

      return [
        <GridActionsCellItem
          size="small"
          label="Change Order Manager"
          disableRipple
          ref={anchorRefEdit}
          icon={
            <>
              <Stack direction="row" alignItems="center" columnGap={0.5}>
                <Tooltip title={tooltipText} placement="bottom" arrow>
                <div tabIndex={0}>
                  <Avatar
                    text={orderManagerInitials}
                    bgcolor={bgcolor}
                    color={color}
                    height={24}
                    width={24}
                    fontSize="12px !important"
                    lineHeight="16px"
                    fontWeight={400}
                  />
                </div>
                </Tooltip>
              </Stack>
              <OrderManagerMenu
                open={open}
                omRole={omRole}
                anchorEl={anchorRefEdit}
                assignedOrderManagerId={assignedOrderManager?.id}
                orderManagers={orderManagers}
                handleSelect={handleSelect}
              />
            </>
          }
          onClick={handleClick}
        />,
      ];
    },
  };
};

export default useGetManagersColumn;
