/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import * as MaterialIcons from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { ORDER_STATUS_ICON_MAP } from './constants';
import StatusHistoryIconProps from './types';
import * as StatusHistoryIconStyles from './styles';
import { OrderStatus } from 'global-constants';

const StatusHistoryIcon = (props: StatusHistoryIconProps) => {
  const styles = StatusHistoryIconStyles;
  const { isActive, activeBGColor, activeTextColor, toStatus } = props;

  const avatarStyles = {
    ...styles.IconAvatarSx,
    ...(isActive ? { backgroundColor: activeBGColor } : {}),
    ...(isActive ? { color: activeTextColor } : {}),
  };

  const Icon =
    toStatus in ORDER_STATUS_ICON_MAP
      ? ORDER_STATUS_ICON_MAP[toStatus]
      : MaterialIcons.Check;

  const customList = [
    OrderStatus.PENDING_RETURN,
    OrderStatus.IN_RETURN,
    OrderStatus.PENDING_RECYCLE,
  ];
  const isCustom = customList.includes(toStatus as OrderStatus);
  const resolvedIconSx = styles.IconSx(isCustom);

  return (
    <Avatar sx={avatarStyles}>
      <Icon sx={resolvedIconSx} />
    </Avatar>
  );
};

export default StatusHistoryIcon;
