import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const IconAvatarSx: SxProps = {
  backgroundColor: theme.palette.colors.grayBD,
  color: theme.palette.colors.white,
  height: '24px',
  width: '24px',
};

export const IconSx: SxProps = {
  height: '16px',
  width: '16px',
};

export const CustomIconSx = {
  '& .MuiSvgIcon-root': {
    height: '20px !important',
    width: '20px !important',
    position: 'relative',
    top: '3px',
    left: '3px',
  },
};
