import { gql } from '@apollo/client';

export const UPDATE_ORDER_MANAGER_ON_ORDER = gql`
  mutation updateOrder(
    $id: ID!
    $orderManagerId: ID
    $otherManagerId: String
    $assetManagerId: String
    $procurementManagerId: String
  ) {
    updateOrder(
      orderUpdateInput: {
        id: $id
        orderManagerId: $orderManagerId
        otherManagerId: $otherManagerId
        assetManagerId: $assetManagerId
        procurementManagerId: $procurementManagerId
      }
    ) {
      id
    }
  }
`;
