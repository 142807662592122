/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { OrderStatus } from 'global-constants';
import AdditionalOrderStatusDetailsProps from './types';
import useGetHandleClickOrderItemsLink from './hooks';
import * as AdditionalOrderStatusDetailsStyles from './styles';

const AdditionalOrderStatusDetails = (props: AdditionalOrderStatusDetailsProps) => {
  const styles = AdditionalOrderStatusDetailsStyles;
  if (props.toStatus !== OrderStatus.EXCEPTION) return <></>;

  const handleLinkClick = useGetHandleClickOrderItemsLink();

  return (
    <Typography variant="body1" sx={styles.ExceptionTypeSx}>
      Logistics issue delaying order fulfillment.
      Please see{' '}
      <Link
        sx={styles.ExceptionOrderItemsLinkSx}
        onClick={handleLinkClick}
      >
        Order items 
      </Link>{' '}
      tab for more information about this exception. If you need help, please
      {' '}
      <Link
        sx={styles.ExceptionContactSupportLinkSx}
        href="mailto:hello@allwhere.co"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact support.
      </Link>
    </Typography>
  );
};

export default AdditionalOrderStatusDetails;
