import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DEFAULT_FILTER } from './constants';
import { FilterProps } from './types';
import { FilterContainerSx, FilterSx } from './styles';

const Filter = (props: FilterProps) => {
  const isActive =
    props.currentFilterId === props.item?.id ||
    (props.currentFilterId === DEFAULT_FILTER && !props.item);

  const onClickSetCurrentFilterId = () => {
    props.setCurrentFilterId(props.item?.id || DEFAULT_FILTER);
  };

  return (
    <Stack sx={FilterContainerSx} data-testid={props.item?.name}>
      <Typography
        id={props.item?.id || DEFAULT_FILTER}
        variant="body1"
        color="colors.boulderWN"
        sx={FilterSx}
        onClick={onClickSetCurrentFilterId}
      >
        {props.item?.name || DEFAULT_FILTER}
      </Typography>
      {isActive && <CheckIcon />}
    </Stack>
  );
};

export default Filter;
