import { createAsyncThunk } from '@reduxjs/toolkit';
import * as restCredentialedCollaboratorsAPI from 'api/rest/credentialedCollaborators';
import * as restOrganizationAPI from 'api/rest/organizations';

export const getCredentialedCollaborator = createAsyncThunk(
  'global/getCredentialedCollaborator',
  async (params: { collaboratorId: string }) => {
    return restCredentialedCollaboratorsAPI.getCredentialedCollaborator({
      collaboratorId: params.collaboratorId,
    });
  }
);

export const getOrganization = createAsyncThunk(
  'global/getOrganization',
  async (params: { organizationId: string }) => {
    return restOrganizationAPI.getOrganization(params.organizationId);
  }
);
