import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getCredentialedCollaborator,
  getOrganization,
} from 'store/slices/global/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

export const useSetGlobalAuth0Collaborator = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (user?.collaboratorId) {
      dispatch(
        getCredentialedCollaborator({ collaboratorId: user?.collaboratorId })
      );
    }
  }, [user]);
};

export const useSetGlobalAuth0Organization = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (user?.organizationId) {
      dispatch(getOrganization({ organizationId: user?.organizationId }));
    }
  }, [user]);
};

export default useSetGlobalAuth0Collaborator;
