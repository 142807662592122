import React from 'react';

import {
  AssetConditionEnum,
  AssetStatus,
  Depot,
  ProductStatus,
  OrderStatus,
  SortDirection,
  EmploymentStatusEnum,
} from 'global-constants';

import { ShipmentStatus, ShipmentSubstatus } from 'globals/constants/shipment';

export type BaseEntity = {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
};

// TODO: Make this the new Address type
export type RecipientAddress = {
  streetAddress1: string;
  streetAddress2?: string | null;
  zipCode: string;
  city: string;
  state?: string;
  principalRegion?: string;
  country: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
};

export enum CollaboratorAddressType {
  HOME = 'HOME',
  WORK = 'WORK',
}

export const HOME = CollaboratorAddressType.HOME as const;
export const WORK = CollaboratorAddressType.WORK as const;

export type SelectableRecipientAddress = RecipientAddress & {
  id: string;
  collaboratorId: string;
  isHome?: boolean;
  isWork?: boolean;
};

export type UnitedStatesAddress = Omit<RecipientAddress, 'principalRegion'>;

export type InternationalAddress = Omit<RecipientAddress, 'state'>;

export type Address = UnitedStatesAddress | InternationalAddress;

export type AssetImage = {
  id: string;
  image: Image;
};

export type Image = {
  id: string;
  name?: string;
  url: string;
};

export type Asset = {
  id: string;
  createdAt?: string;
  recordId?: string;
  color?: string;
  dateOfLastCheckOut?: string;
  depotLocation?: Depot;
  displaySize?: string;
  make?: string;
  memory?: string;
  model?: string;
  keyboard?: string;
  serialNumber?: string;
  status?: AssetStatus;
  storage?: string;
  processor?: string;
  type: string;
  name?: string;
  updatedAt?: string;
  inventoryLog?: {
    dateOfLastCheckIn?: string;
    dateOfLastCheckOut?: string;
    depotId?: string;
  };
  assetNumber?: string;
  lastOrderItemNumber?: string;
  associatedOrders?: Order[];
  depot?: AllwhereDepot;
  assetType?: AssetType;
  cosmeticCondition?: AssetConditionEnum;
  deviceAssessment?: string;
  assignee?: Collaborator;
  assetImages?: AssetImage[];
  organization?: Organization;
  purchaseDate?: string;
  warrantyExpiration?: string;
  isWarrantyExpirationVerified?: boolean;
  isPurchaseDateVerified?: boolean;
  customerNote?: string;
};

export type AssetDevice = Omit<Asset, 'id' | 'type'> & {
  id?: string;
  type?: string;
};

export type CollaboratorAddress = {
  id: string;
  collaboratorId: string;
  addressId: string;
  isHome: boolean;
  isWork: boolean;
};

export type HomeAddress = {
  id: string;
  streetAddress1: string;
  streetAddress2: string | null;
  zipCode: string;
  city: string;
  state: string | null;
  principalRegion: string | null;
  country: string;
};

export type WorkAddress = HomeAddress;

export type Collaborator = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  workEmail?: string;
  personalEmail?: string;
  organizationId: string;
  phoneNumber?: string | null;
  assets?: Asset[];
  group?: string[] | null;
  preferredName?: string | null;
  hrisEmployeeId?: string | null;
  primaryAddressId?: string | null;
  hireDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  // birthday?: string | null;
  employmentStatus?: EmploymentStatusEnum | null;
  avatar?: string | null;
  homeAddresses?: HomeAddress[] | null;
  workAddresses?: WorkAddress[] | null;
  collaboratorAddresses?: CollaboratorAddress[] | null;
  publicId?: string;
};

export type AllwhereDepot = {
  id: string;
  name: string;
  displayName?: string;
  address?: Address;
};

export type AssetType = {
  id: string;
  name: string;
};

// TODO: Remove this type once of airtable
export type OrderItem = {
  id: string;
  productTitle: string;
  trackingNumber: string;
  trackingLink: string;
  serialNumber: string;
  source: string;
};

export type Tracking = {
  id: string;
  shipmentId: string;
  trackingLink?: string | null;
  trackingNumber?: string | null;
  expectedDeliveryDate?: string | null;
};

export type Shipment = {
  id: string;
  status: ShipmentStatus;
  shipmentItems: { orderItem: NewOrderItem }[];
  substatus?: ShipmentSubstatus;
  substatusDescription?: string;
  trackings?: Tracking[];
};

export type NewOrderItem = {
  id: string;
  orderItemNumber?: string;
  mdmPin?: string;
  source: string;
  serialNumber: string;
  tracking?: Tracking;
  productVariant?: {
    product?: {
      name: string;
    };
    sku?: string;
  };
  asset?: Asset;
  insuranceRequested?: string;
  quantity?: number;
  processDevice?: boolean;
  knownIssues?: string;
  inboundOrderItemId?: string;
  protectionPlan?: string;
  shipmentStatus?: ShipmentStatus;
  shipment?: Shipment;
};

export type OrderType = Partial<Omit<BaseEntity, 'id'>> & {
  id: string;
  name: string;
  hasAssets?: boolean;
  hasProducts?: boolean;
  toDepot?: boolean;
  toRecipient?: boolean;
  toDropRecipient?: boolean;
  shippingTypeOptions?: ShippingTypeOption[];
};

export type ShippingTypeOption = Partial<BaseEntity> & {
  orderTypeId?: string;
  shippingTypeId?: string;
  price?: string;
  days?: number;
  shippingType?: ShippingType;
  orderType?: OrderType;
};

export type FlatShippingInfo = {
  shippingTypeId?: string;
  shippingTypeName: string;
  days: number;
  price: string | null;
  description?: string;
};

//Is not directly tied to a Collaborator
export type OrderManager = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string | null;
};

//Is not directly tied to a Collaborator
export type Recipient = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  collaboratorId: string;
};

export type Purchaser = Pick<
  Collaborator,
  'id' | 'workEmail' | 'personalEmail' | 'email' | 'firstName' | 'lastName'
>;

export type ShippingType = BaseEntity & {
  name: string;
  description: string;
  shippingTypeOptions?: ShippingTypeOption[];
};

export interface IDepot extends BaseEntity {
  addressId?: string;
  address?: Address;
  name: string;
  displayName?: string;
  isActive?: boolean;
}

export type Order = {
  id: string;
  finalDestination?: string | null;
  depotId?: string;
  depot?: Pick<IDepot, 'id' | 'name'>;
  dropRecipientId?: string;
  orderNumber: string;
  createdAt: string;
  dueDate: string | null;
  orderType: OrderType;
  organization?: Pick<Organization, 'id' | 'name'>;
  organizationObject: Pick<Organization, 'id' | 'name'>;
  orderManagerId?: string | null;
  procurementManagerId?: string | null;
  assetManagerId?: string | null;
  otherManagerId?: string | null;
  orderManager?: OrderManager | null;
  procurementManager?: OrderManager | null;
  assetManager?: OrderManager | null;
  otherManager?: OrderManager | null;
  recipient?: Recipient;
  dropRecipient?: Recipient;
  purchaser?: Purchaser;
  status: OrderStatus;
  checkoutNotes?: string;
  internalNotes?: string;
  rush?: boolean;
  orderItems?: Partial<NewOrderItem>[];
  sendOrderNotifications: boolean;
  shippingTypeId?: string;
  shippingType?: ShippingType;
  shipments?: Shipment[];
};

export type Organization = {
  appleDepNumber: string;
  billingInstructions: string;
  billingNumber: string;
  createdAt: string;
  deletedAt: string | null;
  id: string;
  internalNotes: string;
  name: string;
  serviceFee: string;
  updatedAt: string;
  hrisEmployeeDataSyncedAt: Date | null;
  publicId?: string;
};

export type RetrievalOrigin = {
  id: string;
  countryName: string;
  index: number;
  retrievalDepots: RetrievalDepot[];
};

export type RetrievalDepot = {
  depotId: string;
  name: string;
  retrievalRouteId: string;
  allowCustomDepot: boolean;
};

export type ColorProps = {
  textColor?: string;
  backgroundColor?: string;
};

export type SortOrder = {
  direction: SortDirection;
  field: string;
};

export type InventoryReceipt = {
  id: string;
  assetId: string;
  inboundOrderItemId: string;
  outboundOrderItemId?: string;
  depotId: string;
  checkedInAt: string;
  checkedOutAt?: string;
  lastTouchedAt?: string;
  productStatus?: ProductStatus;
  identifier?: string;
  inboundOrderItem?: {
    orderItemNumber?: string;
  };
  outboundOrderItem?: {
    orderItemNumber?: string;
  };
};

export type StatusHistoryStep = {
  stepKey: string;
  stepLabelCopy: string;
  stepIcon: React.ElementType;
  iconColor: string;
  createdAt: string;
};

export type HistoryStepperProps = {
  stepperTitle: string;
  steps: StatusHistoryStep[];
};

export type AutoCompleteOptionTuple = [
  key: string,
  value: string,
  optionName: string,
];

export const DEFAULT_AUTOCOMPLETE_OPTIONS: AutoCompleteOptionTuple[] = [
  ['', '', 'No options'],
];

/**
 * @description GenericFunction type only concerned with the output type.
 */
export type GenericFunction<T> = (...args: any[]) => T;

export type FilterBankEntry = {
  queryValue: string;
  queryField: string;
  displayValue: string;
  selectedOptionsList: string;
};

export type AssetService = {
  id: string;
  orderType: OrderType;
  assetStatus: AssetStatus;
  assetType: AssetType;
  depotServices: DepotService[];
};

export type DepotService = {
  id: string;
  depot: AllwhereDepot;
  assetService: AssetService;
};

export interface NotificationType extends BaseEntity {
  name?: string | null;
  typeName?: string | null;
  recordType?: string | null;
  deletedAt?: Date | null;
}

export interface StatusTransitionRow extends Omit<BaseEntity, 'id'> {
  id?: string;
  fromStatus: string;
  toStatus: string;
  recordId: string;
}

export interface StatusTransitionConfigTable {
  [x: string]: {
    displayStatus: string;
    activeBGColor: string;
    activeTextColor: string;
  };
}
