import { InventoryProductTitles, OrderType } from 'global-constants';
import { NewOrderItem } from 'types';

export const getItemProductTitle = (
  orderItem: Partial<NewOrderItem>,
  orderTypeName: string
  // eslint-disable-next-line sonarjs/cognitive-complexity
): string => {
  const assetNumber = orderItem?.asset?.assetNumber ?? '';
  const inboundOrderItemId = orderItem?.inboundOrderItemId ?? '';
  if (orderItem.productVariant?.product?.name) {
    return orderItem.productVariant.product.name;
  }

  switch (orderTypeName) {
    case OrderType.RETRIEVAL:
    case OrderType.DROP_RETRIEVAL:
    case OrderType.BULK_RETRIEVAL:
      if (assetNumber) return `Inbound retrieval kit with asset ${assetNumber}`;
      return inboundOrderItemId
        ? 'Outbound retrieval kit'
        : InventoryProductTitles.RETRIEVAL_KIT;
    case OrderType.DEPLOYMENT:
      return assetNumber
        ? `Deployment of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_FROM_INVENTORY;
    case OrderType.PROCESS:
      return assetNumber
        ? `Process of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_PROCESS;
    case OrderType.PROCUREMENT_TO_RECIPIENT:
    case OrderType.PROCUREMENT_TO_DEPOT:
      return assetNumber
        ? `Procurement of asset ${assetNumber}`
        : InventoryProductTitles.PROCUREMENT;
    case OrderType.RECYCLE:
      return assetNumber
        ? `${orderTypeName} of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_RECYCLE;
    case OrderType.REPAIR:
      return assetNumber
        ? `${orderTypeName} of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_REPAIR;
    case OrderType.RESELL:
      return assetNumber
        ? `${orderTypeName} of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_RESELL;
    case OrderType.DEVICE_UNLOCK:
      return assetNumber
        ? `Device unlock of asset ${assetNumber}`
        : InventoryProductTitles.DEVICE_UNLOCK;
    default:
      return '';
  }
};
