import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { StatusTransitionConfigTable, StatusTransitionRow } from 'types';
import { GetIconCallback, UseGetAssetStatusTimeLineMapper } from './types';
import { StatusTransitionItem } from 'components/status_history_timeline/types';

const useGetAssetStatusTimelineMapper: UseGetAssetStatusTimeLineMapper =
  (
    statusConfigTable: StatusTransitionConfigTable,
    getIconCallback: GetIconCallback,
  ) =>
  (
    transitionItem: StatusTransitionRow,
    isActive: boolean,
  ): StatusTransitionItem => {
    const { toStatus, createdAt } = transitionItem;
    const transitionConfig =
      toStatus in statusConfigTable
        ? statusConfigTable[toStatus]
        : statusConfigTable.FALLBACK;
    const displayStatus =
      toStatus in statusConfigTable
        ? transitionConfig.displayStatus
        : toStatus.split('_').map(capitalize).join(' ');
    const { activeBGColor, activeTextColor } = transitionConfig;
    const dateTimeStr = moment(createdAt).format('MM/DD/YYYY h:mm A');

    return {
      IconAvatar: getIconCallback({
        isActive,
        activeBGColor,
        activeTextColor,
        toStatus,
      }),
      displayStatus,
      dateTimeStr,
    };
  };

export default useGetAssetStatusTimelineMapper;
