/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useGetOrderStatusTransitions from './hooks';
import OrderStatusTransitionTimeLine from '../OrderStatusTransitionTimeLine';
import { ORDER_STATUS_HISTORY_BOX_CONTAINER_ID } from './constants';
import OrderStatusHistoryPanelProps from './types';
import * as OrderStatusHistoryPanelStyles from './styles';

const OrderStatusHistoryPanel = (props: OrderStatusHistoryPanelProps) => {
  const styles = OrderStatusHistoryPanelStyles;
  const { orderId, createdAt, currentStatus } = props;

  if (!orderId) return <></>;

  const {
    statusTransitionRecords,
    statusTransitionLoading,
    revealMoreItems,
    revealItemsCount,
  } = useGetOrderStatusTransitions(orderId);

  const actualRecords =
    statusTransitionRecords.length > 0
      ? statusTransitionRecords.length + 1
      : statusTransitionRecords.length;

  const canFetchMoreItems = actualRecords > revealItemsCount;
  const remaining = actualRecords - revealItemsCount;

  return (
    <Grid item container direction="column" sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Stack sx={styles.OrderStatusHistoryStackSx} direction="row">
          {statusTransitionLoading ? (
            <CircularProgress color="primary" size={40} />
          ) : (
            <Box
              id={ORDER_STATUS_HISTORY_BOX_CONTAINER_ID}
              sx={styles.getOrderStatusHistoryScrollBoxSx(true)}
            >
              <OrderStatusTransitionTimeLine
                statusTransitionRecords={statusTransitionRecords}
                revealItemsCount={revealItemsCount}
                createdAt={createdAt}
                currentStatus={currentStatus}
              />
            </Box>
          )}
        </Stack>
      </Grid>
      {canFetchMoreItems ? (
        <Grid item xs={12}>
          <Box sx={{ width: '100%', height: '44px' }}>
            <Button
              id="order-details-fetch-more-status-history"
              sx={styles.FetchMoreOrderHistorySx}
              onClick={revealMoreItems}
              disableRipple
            >
              Show {Math.min(10, remaining)} more
            </Button>
          </Box>
        </Grid>
      ) : (
        <> </>
      )}
    </Grid>
  );
};

export default OrderStatusHistoryPanel;
