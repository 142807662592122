import { Box, Link, Typography } from "@mui/material"
import { locationBoxSx, locationSubtextSx } from "../styles";

export const RESTRICTED_LOCATIONS: Record<string, string> = {
    'Canada location': 'northern Canada'
};
export const FAQ = 'https://faq.allwhere.co/en_us/retrieval-service-details-by-region-SJcTdk9u3';

export const dynamicLocationLabel = (location: string): JSX.Element => {
    return (
        RESTRICTED_LOCATIONS?.[location] ? (
            <Box sx={locationBoxSx}>
                <Typography>
                    {location}
                </Typography>
                <Typography variant="body2" sx={locationSubtextSx}>
                    Please note some areas in {RESTRICTED_LOCATIONS[location]} may be restricted. See our&nbsp;
                    <Link 
                        target="_blank"
                        rel="noopener"
                        color="colors.black" 
                        href={FAQ}>FAQs
                    </Link>
                    &nbsp;for details.
                </Typography>
            </Box>)
        : (<Typography>{location}</Typography>)
    );
};
