import { SxProps } from '@mui/material';

export const OrderDetailStackSx: SxProps = {
  borderBottom: '1px solid #DCDCDC',
  margin: '-24px', // to offset padding from parent
  padding: '18px 18px 9px 30px',
  marginBottom: '1rem',
  rowGap: '1rem',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
};

export const OrderDetailTextHeaderSx: SxProps = {
  color: 'colors.grayB7',
  fontSize: '12px',
};

export const OrderDetailTextSx: SxProps = {
  fontSize: '16px',
};

export const getOrderDetailTextSx = (hasManager: boolean): SxProps => ({
  fontSize: '16px',
  ...(hasManager ? {} : { display: 'none' }),
});

export const OrderDetailTextBoldSx: SxProps = {
  ...OrderDetailTextSx,
  fontWeight: 600,
};

export const getOrderDetailGridItemSx = (
  hasContents: boolean = true
): SxProps => {
  const width = hasContents ? '170px' : '85px';
  return {
    minWidth: width,
  };
};
