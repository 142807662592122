import { SxProps } from '@mui/material';

export const AvatarBoxPartialSx = {
  position: 'relative',
  width: '24px',
};

export const StatusNameSx: SxProps = {
  color: '#131414',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '157%',
};

export const StatusDateTimeSx: SxProps = {
  color: '#60605D',
  fontSize: '12px',
  lineHeight: '16px',
};
