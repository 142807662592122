/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import * as MaterialIcons from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { ASSET_STATUS_ICON_MAP, CUSTOM_SVG_STATUS } from './constants';
import StatusHistoryIconProps from './types';
import * as StatusHistoryIconStyles from './styles';

const StatusHistoryIcon = (props: StatusHistoryIconProps) => {
  const styles = StatusHistoryIconStyles;
  const { isActive, activeBGColor, activeTextColor, toStatus } = props;

  const hasCustomIcon = toStatus in CUSTOM_SVG_STATUS;

  const avatarStyles = {
    ...styles.IconAvatarSx,
    ...(isActive ? { backgroundColor: activeBGColor } : {}),
    ...(isActive ? { color: activeTextColor } : {}),
    ...(hasCustomIcon ? styles.CustomIconSx : {}),
  }

  const Icon = toStatus in ASSET_STATUS_ICON_MAP
    ? ASSET_STATUS_ICON_MAP[toStatus] : MaterialIcons.Check;

  return (
    <Avatar sx={avatarStyles}>
      <Icon sx={styles.IconSx}/>
    </Avatar>
  );
};

export default StatusHistoryIcon;
