import { COUNTRY_MATCHER, CountryEnum, UNITED_STATES } from "global-constants";

export const getCountry = (locationName?: string): CountryEnum => {
  const country = COUNTRY_MATCHER[locationName?.replace(' location', '') || UNITED_STATES];

  if (!country) {
    return CountryEnum.UNITED_STATES;
  }

  return COUNTRY_MATCHER[country] as CountryEnum;
}

