/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import AssetStatusTransitionTimeLineProps from './types';
import StatusTransitionTimeline from 'components/status_history_timeline/StatusTransitionTimeLine';
import StatusHistoryIcon from 'pages/AssetDetails/asset_status_history/StatusHistoryIcon';
import useGetAssetStatusTimelineMapper from './hooks';
import { ASSET_STATUS_TRANSITION_CONFIG } from './constants';

const AssetStatusTransitionTimeLine = (
  props: AssetStatusTransitionTimeLineProps,
) => {
  const {
    statusTransitionRecords,
    revealItemsCount,
    createdAt,
    currentStatus,
  } = props;
  const convertToTimelineItem = useGetAssetStatusTimelineMapper(
    ASSET_STATUS_TRANSITION_CONFIG,
    StatusHistoryIcon,
  );

  const doNotRender = !statusTransitionRecords || !revealItemsCount;

  if (doNotRender) return <section>No Asset Status Transition History</section>;

  const transitionRecords = props.statusTransitionRecords.slice(
    0,
    revealItemsCount,
  );

  let transitionTimeLineItems = transitionRecords.map((rec, i) =>
    convertToTimelineItem(rec, i === 0),
  );

  if (!transitionRecords.length) {
    const onlyRecord = {
      toStatus: currentStatus || 'CREATED',
      fromStatus: 'CREATED',
      createdAt: createdAt as unknown as Date,
      recordId: 'current',
    };
    transitionTimeLineItems.push(convertToTimelineItem(onlyRecord, false));
    return (
      <StatusTransitionTimeline
        transitionTimeLineItems={transitionTimeLineItems}
      />
    );
  }

  // Add Initial Entry
  let lastRecord = transitionRecords[transitionRecords.length - 1];
  if (lastRecord?.fromStatus) {
    const finalRecord = { ...lastRecord, toStatus: lastRecord.fromStatus };
    transitionTimeLineItems.push(convertToTimelineItem(finalRecord, false));
  }

  return (
    <StatusTransitionTimeline
      transitionTimeLineItems={transitionTimeLineItems}
    />
  );
};

export default AssetStatusTransitionTimeLine;
