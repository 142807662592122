import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDate } from 'services/date';

export const useGetMerchandiseColumns = (): GridColDef[] => {
  return [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 170,
      filterable: false,
      valueGetter(params) {
        return formatDate(params.row.date);
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 170,
      filterable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 500,
      filterable: false,
    },
    {
      field: 'inventoryQuantity',
      headerName: '# Inventory',
      minWidth: 170,
      filterable: false,
    },
    {
      field: 'deployedQuantity',
      headerName: '# Deployed',
      minWidth: 170,
      filterable: false,
    },
    {
      field: 'total',
      headerName: 'Total',
      minWidth: 170,
      filterable: false,
    },
  ];
};
