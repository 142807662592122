import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Menu, MenuItem, FormControl, InputLabel } from '@mui/material';
import { OrderManager } from 'types';
import { OrderManagerMenuProps } from './types';
import { ManagerRolesMap } from 'pages/OrderManagement/constants';

const OrderManagerMenu = (props: OrderManagerMenuProps) => {
  const selectLabel = `Select ${
    props.omRole && props.omRole in ManagerRolesMap
      ? ManagerRolesMap[props.omRole]
      : 'manager'
  }`;

  const handleChange = (event: SelectChangeEvent) => {
    if (props.handleSelect) {
      props.handleSelect(event.target.value);
    }
  };

  return (
    <Menu open={props.open} anchorEl={props.anchorEl?.current}>
      <FormControl style={{ width: '300px', margin: '8px 16px' }} size="small">
        <InputLabel id="select-order-manager">{selectLabel}</InputLabel>
        <Select
          value={props.assignedOrderManagerId}
          labelId="select-order-manager"
          label={selectLabel}
          onChange={handleChange}
        >
          {props.assignedOrderManagerId && (
            <MenuItem value="none">None</MenuItem>
          )}
          {props.orderManagers.map((orderManager: OrderManager) => (
            <MenuItem
              key={orderManager.id}
              value={orderManager.id}
            >{`${orderManager.firstName} ${orderManager.lastName}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Menu>
  );
};

export default OrderManagerMenu;
