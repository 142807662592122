import { AvatarProps } from './types';

export const getAvatarSx = (props: AvatarProps) => {
  const { fontSize, fontWeight, lineHeight } = props;
  return {
    bgcolor: props.bgcolor || 'colors.maizeYO',
    color: props.color || 'colors.jungleG',
    width: props.width || 'auto',
    height: props.height || 'auto',
    cursor: props.cursor || 'default',
    ...({ fontSize }),
    ...({ fontWeight }),
    ...({ lineHeight }),
    ...(props.hoverBgcolor && {
      '&:hover': {
        bgcolor: props.hoverBgcolor,
      }}),
    };
};
