/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import StatusTransitionItem from '../StatusTransitionItem';
import StatusTransitionTimelineProps from './types';
import * as StatusTransitionTimelineStyles from './styles';

const StatusTransitionTimeline = (props: StatusTransitionTimelineProps) => {
  const styles = StatusTransitionTimelineStyles;

  const statusTransitionItemPropsList = props.transitionTimeLineItems.map(
    (item, index, itemsArray) => ({
      ...item,
      lastStep: index === itemsArray.length - 1,
      keyProp: `${index}-${item.displayStatus}`,
    }),
  );

  const hasOneItem = statusTransitionItemPropsList.length === 1;

  return (
    <Stack direction="column" sx={styles.TimeLineBoxSx} rowGap="24px">
      {hasOneItem ? <></> : <Box sx={styles.DividerSx} />}
      {statusTransitionItemPropsList.map((itemProps, i) => (
        <StatusTransitionItem
          key={`status-transition-item-${i}`}
          {...itemProps}
        />
      ))}
    </Stack>
  );
};

export default StatusTransitionTimeline;
