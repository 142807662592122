/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextIconButton from 'components/TextIconButton';
import LoadingIndictor from 'components/LoadingIndicator';
import { NavigationContext } from 'context/NavigationContext';
import { useSafeQuery } from 'hooks/useSafeQuery';
import OrderDetailsPanel from './components/OrderDetailsPanel';
import OrderItemsPanel from './components/OrderItemsPanel';
import OrderStatusHistoryPanel from './order_status_history/OrderStatusHistoryPanel';
import {
  OrderDetailsTab,
  AsyncMatchStatus,
  ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID,
} from './constants';
import { RETRIEVE_ORDER_DETAILS } from './queries';
import { getInitialOrderDetailsTab, getOrderDetailsPanelProps } from './utils';
import * as OrderDetailsStyles from './styles';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';

const OrderDetails = () => {
  const styles = OrderDetailsStyles;
  const { id: orderIdParam } = useParams();
  const [searchParams] = useSearchParams();
  const orgIdQueryParam = searchParams.get('orgId');
  const panelTabQueryParam = searchParams.get('panelTab');
  const initialTabValue = getInitialOrderDetailsTab(panelTabQueryParam);

  const navigate = useNavigate();
  const navigator = React.useContext(NavigationContext);

  const {
    OnFlagTrueWrapper: OrderStatusHistoryEnabled,
    isFlagOn: orderStatusHistoryShown,
  } = useFeatureFlagService(FeatureFlagNames.VISUALIZE_ORDER_STATUS_HISTORY, {
    debugFlags: true,
  });

  const [orgIdsMatch, setOrgIdsMatch] = React.useState<AsyncMatchStatus>(
    AsyncMatchStatus.PENDING,
  );

  const { data: orderDetailsData, loading: isOrderDetailsLoading } =
    useSafeQuery(RETRIEVE_ORDER_DETAILS, {
      variables: {
        id: orderIdParam,
      },
      fetchPolicy: 'network-only',
      onCompleted: ({ order }) => {
        const incomingOrgId = order?.organizationId ?? 'FALLBACK';
        const queryOrgIdMatches =
          incomingOrgId === orgIdQueryParam
            ? AsyncMatchStatus.MATCH
            : AsyncMatchStatus.NO_MATCH;

        setOrgIdsMatch(queryOrgIdMatches);
      },
    });

  const order = orderDetailsData?.order || {};

  const [orderDetailsPanelTab, setOrderDetailsPanelTab] =
    React.useState<OrderDetailsTab>(initialTabValue);

  const onOrderItemsClick = () => {
    setOrderDetailsPanelTab(OrderDetailsTab.ORDER_ITEMS);
  };

  const orderDetailsPanelProps = getOrderDetailsPanelProps(
    order,
    onOrderItemsClick,
  );

  const handleOnTabChange = (
    _event: React.SyntheticEvent,
    value: OrderDetailsTab,
  ) => {
    setOrderDetailsPanelTab(value);
  };

  React.useEffect(() => {
    if (
      orgIdsMatch === AsyncMatchStatus.PENDING ||
      orgIdsMatch === AsyncMatchStatus.MATCH
    )
      return;
    console.warn(
      ' Redirecting to All Orders. Order did NOT belong to organization from link.',
    );
    navigate(navigator.pathToAllOrders());
  }, [orgIdsMatch]);

  const orgIdsHaveMatch = () => orgIdsMatch === AsyncMatchStatus.MATCH;

  return (
    <>
      <LoadingIndictor loading={isOrderDetailsLoading} />
      <Container sx={styles.MainContainerDivSx}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt="10"
          pb="10px"
        >
          <TextIconButton
            text="Back to All Orders"
            ariaLabel="Navigate back to Orders"
            icon={<ArrowBackIcon />}
            onClick={() => navigate(navigator.pathToAllOrders())}
          />
        </Stack>
        <Typography variant="h2">Order details</Typography>
        <Stack sx={styles.ContentDivSx} alignItems="flex-start">
          {orgIdsHaveMatch() ? (
            <>
              <Typography variant="h6">
                {order.orderNumber ? `Order #${order.orderNumber}` : '...'}
              </Typography>
              <TabContext value={orderDetailsPanelTab}>
                <TabList sx={styles.TabsSx} onChange={handleOnTabChange}>
                  <Tab
                    label={OrderDetailsTab.ORDER_DETAILS}
                    value={OrderDetailsTab.ORDER_DETAILS}
                  />
                  <Tab
                    label={OrderDetailsTab.ORDER_ITEMS}
                    value={OrderDetailsTab.ORDER_ITEMS}
                    data-selectId={
                      ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID
                    }
                  />
                  {orderStatusHistoryShown() ? (
                    <Tab
                      label={OrderDetailsTab.ORDER_STATUS_HISTORY}
                      value={OrderDetailsTab.ORDER_STATUS_HISTORY}
                    />
                  ) : (
                    <> </>
                  )}
                </TabList>
                <Divider sx={styles.TitleDividerSx} />
                {/* END of TabList */}
                <TabPanel
                  sx={styles.TabPanelSx}
                  value={OrderDetailsTab.ORDER_DETAILS}
                >
                  <OrderDetailsPanel {...orderDetailsPanelProps} />
                </TabPanel>
                <TabPanel
                  sx={styles.TabPanelSx}
                  value={OrderDetailsTab.ORDER_ITEMS}
                >
                  <OrderItemsPanel orderId={orderIdParam} />
                </TabPanel>
                <OrderStatusHistoryEnabled>
                  <TabPanel
                    sx={styles.TabPanelSx}
                    value={OrderDetailsTab.ORDER_STATUS_HISTORY}
                  >
                    <OrderStatusHistoryPanel
                      orderId={orderIdParam ?? ''}
                      currentStatus={order.status}
                      createdAt={order.createdAt}
                    />
                  </TabPanel>
                </OrderStatusHistoryEnabled>
              </TabContext>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default OrderDetails;
