import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const IconAvatarSx: SxProps = {
  backgroundColor: theme.palette.colors.grayBD,
  color: theme.palette.colors.white,
  height: '24px',
  width: '24px',
};

export const IconSx = (isCustom: boolean): SxProps =>
  isCustom
    ? {
        height: '20px',
        width: '20px',
        transform: `translate(3px, 3px)`,
      }
    : {
        height: '16px',
        width: '16px',
      };
