import { SxProps, Theme } from '@mui/material';

export const QuestionLabelSx = (theme: Theme): SxProps => ({
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '37px',
  color: 'inherit',

  '& .retrieval-country-tip': {
    position: 'relative',
    top: '2px',
    left: '3px',

    '& > .MuiSvgIcon-root': {
      color: theme.palette.colors.gray70,
    },
  },
});

export const RadioGroupSx: SxProps = {
  marginTop: '1rem',
  width: '75%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  justifyItems: 'start',
  paddingLeft: '1rem',
};

export const RadioButtonSx = (theme: Theme): SxProps => {
  return {
    color: theme.palette.colors.black,
    '&.Mui-checked': {
      color: theme.palette.colors.strongBlue,

      '& ~ .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  };
};

export const SlaTimelineTextSx: SxProps = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '22px',
};

export const locationBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1.25rem',
};

export const locationSubtextSx: SxProps = {
  color: 'colors.gray60',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  width: '150%',
}; 
