import { SxProps } from '@mui/material';

export const TimeLineBoxSx: SxProps = {
  position: 'relative',
  width: '100%',
};

export const DividerSx = {
  borderRight: '1px solid rgba(19, 20, 20, 0.12)',
  height: '100%',
  position: 'absolute',
  width: '11px',
};
