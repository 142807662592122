import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectGlobalAuth0Collaborator,
  selectGlobalAuth0Organization,
  selectGlobalLocation,
} from 'store/slices/global/selectors';
import { setGlobalLocation } from 'store/slices/global/index';

const useTrackGlobalSegment = () => {
  const location = useLocation();
  const globalLocation = useSelector(selectGlobalLocation);
  const dispatch = useDispatch();
  const globalAuth0Collaborator = useSelector(selectGlobalAuth0Collaborator);
  const globalAuth0Organization = useSelector(selectGlobalAuth0Organization);

  React.useEffect(() => {
    if (!globalAuth0Organization || !globalAuth0Collaborator) return;
    const { pathname, search } = location;
    if (
      (pathname === '/orders' || pathname === '/assets') &&
      search.match('view=') &&
      window.analytics
    ) {
      window.analytics.page();
    } else if (
      location?.pathname !== globalLocation?.pathname &&
      window.analytics
    ) {
      dispatch(setGlobalLocation({ pathname, search }));
      window.analytics.page();
    }
  }, [
    location?.pathname,
    location?.search,
    globalLocation?.pathname,
    globalLocation?.search,
    globalAuth0Collaborator,
    globalAuth0Organization,
  ]);
};

export default useTrackGlobalSegment;
