import { gql } from '@apollo/client';

export const getOrderStatusTransitions = (output: string) => gql`
  query getOrderStatusTransitions(
    $orderId: String!
    $limit: Int = 25
    $offset: Int = 0
    $orderDesc: String = "createdAt"
  ) {
    orderStatusTransitions(
      searchOrderStatusTransitionInput: {
        orderId: $orderId
        limit: $limit
        offset: $offset
        orderDesc: $orderDesc
      }
    ) {
      ${output}
    }
  }
`;
