import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, ApolloError } from '@apollo/client';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Container from '@mui/material/Container';
import FilledInput from '@mui/material/FilledInput';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  GridCallbackDetails,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
  GridFilterItem,
  GridRowSelectionModel,
  GridCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
  FilterChangeReason,
} from 'components/DataGrid/constants';
import ArchiveEmployeeModal from './components/ArchiveEmployeeConfirmationModal';
import EmployeeActionsGroup from './components/EmployeeActionsGroup';
import {
  ADD_EMPLOYEE_BUTTON_COPY,
  EMPLOYEE_SEARCH_DELAY_MS,
  DEFAULT_EMPLOYEE_SORT_ORDER,
  EmployeeFilterColumns,
  ADD_EMPLOYEE_MODAL,
  EDIT_EMPLOYEE_MODAL,
} from './constants';
import DataGrid from 'components/DataGrid';
import {
  removeDuplicateFilters,
  areFiltersApplied,
} from 'components/DataGrid/utils';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import { Button } from 'components';
import {
  NotificationMessages,
  CustomNotifications,
} from 'components/GlobalToastNotification/constants';
import RetrievalConfirmationModal from 'components/RetrievalConfirmationModal';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import {
  SortDirection,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  GENERIC_PAGE_SIZE_OPTIONS,
  DEFAULT_PAGINATION_MODEL,
  Entities,
  HttpCodes,
  FormModeContext,
} from 'global-constants';
import { SEARCH_COLLABORATORS_BY_ORG_ID } from './queries';
import { DELETE_COLLABORATOR_BY_ID } from './mutations';
import * as EmployeeStyles from './styles';
import { Collaborator, SortOrder } from 'types';
import { useGetEmployeeColumns } from './hooks/useGetEmployeesColumns';
import EmployeeAddModal from 'pages/Employees/components/EmployeeAddModal';
import EmployeeEditModal from 'pages/Employees/components/EmployeeEditModal';
import { DataGridSx } from 'components/DataGrid/styles';
import CustomGridToolbar from 'components/CustomGridToolbar';
import { employeeFilterSlice } from './slice';
import { getEnumValues } from 'services/enums';
import GAButton from 'components/buttons/google_analytics/GAButton';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';
import * as Sentry from '@sentry/react';

const Employees = () => {
  const apiRef = useGridApiRef();
  const styles = EmployeeStyles;
  const [employeeFullTextSearch, setEmployeeFullTextSearch] =
    React.useState<string>('');

  const debouncedEmployeeFullTextQuery = useDebouncedQuery(
    employeeFullTextSearch,
    EMPLOYEE_SEARCH_DELAY_MS
  );

  const [employeeSortOrder, setEmployeeSortOrder] =
    React.useState<SortOrder | null>(DEFAULT_EMPLOYEE_SORT_ORDER);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const [employeeFilterState, filterDispatch] = React.useReducer(
    employeeFilterSlice.reducer,
    employeeFilterSlice.getInitialState()
  );

  const [employeeFilterModel, setEmployeeFilterModel] =
    React.useState<GridFilterModel>({ items: [] });

  // Row selection
  const [rowSelectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => []);
  const [selectedEmployee, setSelectedEmployee] = React.useState<
    Collaborator | undefined
  >(undefined);
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');

  React.useEffect(() => {
    const skipFullTextSearchFilter = debouncedEmployeeFullTextQuery ?? true;
    if (!debouncedEmployeeFullTextQuery && skipFullTextSearchFilter) return;

    filterDispatch(
      employeeFilterSlice.actions.setEmployeeFilter({
        collaboratorFullTextSearch: debouncedEmployeeFullTextQuery,
      })
    );
  }, [debouncedEmployeeFullTextQuery]);

  const {
    firstName: firstNameFilter,
    lastName: lastNameFilter,
    email: emailFilter,
    personalEmail: personalEmailFilter,
    workEmail: workEmailFilter,
    collaboratorFullTextSearch: fullTextSearchFilter,
  } = employeeFilterState;

  const userOrganization = useGetUserOrganization();

  const variables = {
    organizationId: userOrganization?.id,
    offset: paginationModel.page * paginationModel.pageSize,
    ...(employeeSortOrder && {
      [employeeSortOrder.direction]: employeeSortOrder.field,
    }),
    limit: paginationModel.pageSize,
    ...(firstNameFilter && {
      firstName: firstNameFilter,
    }),
    ...(lastNameFilter && { lastName: lastNameFilter }),
    ...(emailFilter && { email: emailFilter }),
    ...(personalEmailFilter && { personalEmail: personalEmailFilter }),
    ...(workEmailFilter && { workEmail: workEmailFilter }),
    ...(fullTextSearchFilter && {
      collaboratorFullTextSearch: fullTextSearchFilter,
    }),
  };
  const {
    data,
    loading,
    refetch: refetchCollaboratorsQuery,
  } = useQuery(SEARCH_COLLABORATORS_BY_ORG_ID, {
    variables,
    fetchPolicy: 'network-only',
    onError: (error) => {},
  });

  const collaboratorsData = data?.collaborators?.collaborators || [];
  const collaboratorsRowCount = data?.collaborators?.count || 0;

  const handleSortModelChange = (
    sortModel: GridSortModel,
    _callbackDetails: GridCallbackDetails
  ): void => {
    if (!sortModel.length) {
      setEmployeeSortOrder(DEFAULT_EMPLOYEE_SORT_ORDER);
      return;
    }
    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setEmployeeSortOrder({
      direction: serverSortDirection,
      field,
    });
  };

  const resetFilters = () => {
    if (debouncedEmployeeFullTextQuery) {
      filterDispatch(
        employeeFilterSlice.actions.resetAllExcept('collaboratorFullTextSearch')
      );
    } else {
      filterDispatch(employeeFilterSlice.actions.reset());
    }
  };

  const resetSelectFilters = (model: GridFilterModel) => {
    const filteredFieldsTable = model.items.reduce((table, item) => {
      table[item.field] = true;
      return table;
    }, {} as Record<string, boolean>);
    const availableFields = getEnumValues(EmployeeFilterColumns);

    availableFields.forEach((field) => {
      if (!filteredFieldsTable[field]) {
        filterDispatch(employeeFilterSlice.actions.resetField(field));
      }
    });
  };

  const upsertFilterItems = (
    model: GridFilterModel,
    resetFields: string[]
  ): void => {
    resetFields.forEach((fieldToReset) => {
      filterDispatch(employeeFilterSlice.actions.resetField(fieldToReset));
    });

    if (model.items.length > 0) {
      setEmployeeFullTextSearch('');
    }

    model.items.forEach((item: GridFilterItem) => {
      filterDispatch(
        employeeFilterSlice.actions.setEmployeeFilter({
          [item.field]: item.value,
        })
      );
    });
  };

  const getUpsertFieldsToReset = (model: GridFilterModel) => {
    const employeeFilterStateKeys = Object.keys(employeeFilterState);
    const modelItemFields = model.items.map((item) => item?.field);
    return employeeFilterStateKeys.reduce((arr, key) => {
      if (!modelItemFields.includes(key)) {
        arr.push(key);
      }
      return arr;
    }, [] as string[]);
  };

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    const isRowBeingUnSelected = ids.length === 0;
    if (isRowBeingUnSelected) {
      setSelectionModel([]);
      setSelectedEmployee(undefined);
    } else {
      const selectedEmployeeId = ids[ids.length - 1];
      const selectedEmployee = collaboratorsData.filter(
        (collaborator: Collaborator) => collaborator.id === selectedEmployeeId
      )[0];
      setSelectionModel([selectedEmployeeId]);
      setSelectedEmployee(selectedEmployee);
    }
  };

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.id === rowSelectionModel[0]) {
      onRowSelectionModelChange([]);
    } else {
      onRowSelectionModelChange([params.id]);
    }
  };

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  const handleOnFilterModelChange = (
    model: GridFilterModel,
    details: GridCallbackDetails<'filter'>
  ) => {
    removeDuplicateFilters(model);
    const { reason } = details;
    const reasonIsDeleteOrUndefined =
      reason === FilterChangeReason.DELETE_ITEM || reason === undefined;

    if (reasonIsDeleteOrUndefined && areFiltersApplied(model.items)) {
      resetFilters();
    }

    if (reasonIsDeleteOrUndefined && !areFiltersApplied(model.items)) {
      resetSelectFilters(model);
    }

    if (reason === FilterChangeReason.UPSERT_ITEM) {
      const fieldsToReset = getUpsertFieldsToReset(model);
      upsertFilterItems(model, fieldsToReset);
    }
    setEmployeeFilterModel(model);
  };

  const dispatch = useDispatch();

  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );

  const [showArchiveEmployeeModal, setShowArchiveEmployeeModal] =
    React.useState(false);

  const [addEmployeeModalKey, setAddEmployeeModalKey] =
    React.useState<string>(ADD_EMPLOYEE_MODAL);

  const [editEmployeeModalKey, setEditEmployeeModalKey] =
    React.useState<string>(EDIT_EMPLOYEE_MODAL);

  const showAddEmployeeModal =
    currentModal === GlobalModals.ADD_EMPLOYEE_INFORMATION;

  const showAddEmployeeInfoModal = () => {
    dispatch(
      modalSlice.actions.setCurrentModal(GlobalModals.ADD_EMPLOYEE_INFORMATION)
    );
  };

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleCloseAddNewPersonModal = () => {
    setAddEmployeeModalKey(() => nanoid());
    dispatch(modalSlice.actions.reset());
  };

  const showRetrievalConfirmationModal =
    currentModal === GlobalModals.RETRIEVAL_CONFIRMATION && !!selectedEmployee;

  const handleCloseRetrievalConfirmationModal = () => {
    dispatch(modalSlice.actions.reset());
  };

  const showEditEmployeeModal =
    currentModal === GlobalModals.EDIT_EMPLOYEE_INFORMATION &&
    !!selectedEmployee;

  const showEditEmployeeInfoModal = () => {
    dispatch(
      modalSlice.actions.setCurrentModal(GlobalModals.EDIT_EMPLOYEE_INFORMATION)
    );
  };

  const handleCloseEditEmployeeInfoModal = () => {
    setEditEmployeeModalKey(() => nanoid());
    dispatch(modalSlice.actions.reset());
  };

  const employeeColumns = useGetEmployeeColumns(
    rowSelectionModel,
    rowHoveringModel
  );

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...model, page: 0 });
    } else {
      setPaginationModel(model);
    }
  };

  const [mutate, { loading: loadingMutation }] = useSafeMutation(
    DELETE_COLLABORATOR_BY_ID
  );

  const openArchiveEmployeeModal = () => {
    setShowArchiveEmployeeModal(true);
  };

  const closeArchiveEmployeeModal = () => {
    setShowArchiveEmployeeModal(false);
  };

  const archiveEmployee = () => {
    mutate({
      variables: { id: selectedEmployee?.id },
      onCompleted(data: any) {
        const noticeContent =
          data?.deleteCollaborator?.data?.message ??
          NotificationMessages.CHANGES_SAVED_SUCCESS;

        closeArchiveEmployeeModal();
        refetchCollaboratorsQuery();
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent,
          })
        );
      },
      onError(apolloErr: ApolloError) {
        const { graphQLErrors = [{ message: '' }] } = apolloErr;
        const errorNotice = graphQLErrors[0].message.includes(
          `${HttpCodes.NOT_FOUND}`
        )
          ? CustomNotifications.entityNotFound(Entities.EMPLOYEE)
          : NotificationMessages.GENERIC_SOMETHING_WENT_WRONG;

        closeArchiveEmployeeModal();
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: errorNotice,
          })
        );
      },
    });
  };

  //Result to page 0 if search term gets changed to empty
  React.useEffect(() => {
    if (!debouncedEmployeeFullTextQuery) {
      setPaginationModel(DEFAULT_PAGINATION_MODEL);
    }
  }, [debouncedEmployeeFullTextQuery]);

  const loadingEmployees = loadingMutation || loading;

  return (
    <>
      <Container sx={styles.MainEmployeesContainerSx}>
        <Stack spacing={1.25} sx={styles.EmployeeHeaderStackSx}>
          <Stack spacing={1}>
            <Typography variant="h1" sx={styles.EmployeeHeaderTextSx}>
              Employee management
            </Typography>
            <Typography variant="body2" sx={styles.EmployeeSubHeaderTextSx}>
              Select an employee to view and edit details.
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <FilledInput
              placeholder="Search employees"
              disableUnderline={true}
              startAdornment={<SearchIcon />}
              value={employeeFullTextSearch}
              onChange={(e) => setEmployeeFullTextSearch(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              sx={{
                width: '45%',
                height: '34px',
                border: `2px solid ${isFocused ? 'black' : 'transparent'}`,
              }}
            />
            <GAIconButton
              gaContext={{
                purpose: 'Opens new employee modal',
                textCopy: ADD_EMPLOYEE_BUTTON_COPY,
                navigates_to: 'N/A',
              }}
              aria-label="add new employee"
              color="secondary"
              size="small"
              disableRipple
              onClick={showAddEmployeeInfoModal}
            >
              <AddCircleOutlineIcon fontSize="small" /> &nbsp;
              <Typography variant="button" sx={styles.NewEmployeeButtonSx}>
                {ADD_EMPLOYEE_BUTTON_COPY}
              </Typography>
            </GAIconButton>
          </Stack>
          <Box sx={styles.DataGridBoxSx}>
            <DataGrid
              apiRef={apiRef}
              pagination
              autoHeight={false}
              paginationMode="server"
              filterMode="server"
              filterModel={employeeFilterModel}
              disableRowSelectionOnClick
              customToolbar={CustomGridToolbar}
              rows={collaboratorsData}
              rowCount={collaboratorsRowCount}
              rowSelectionModel={rowSelectionModel}
              columns={employeeColumns}
              loading={loadingEmployees}
              paginationModel={paginationModel}
              onPaginationModelChange={handleOnPaginationModelChange}
              pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleOnFilterModelChange}
              onCellClick={handleOnCellClick}
              sortingMode="server"
              disableColumnPinning
              disableColumnReorder
              disableColumnSelector
              disableMultipleColumnsSorting
              disableMultipleRowSelection
              hideFooterSelectedRowCount
              keepNonExistentRowsSelected
              checkboxSelection
              slotProps={{
                panel: {
                  placement: 'bottom-end',
                  sx: DataGridSx,
                },
                filterPanel: {
                  sx: { visibility: 'hidden' },
                },
                row: {
                  onMouseOver: handleOnRowOver,
                  onMouseLeave: handleOnRowLeave,
                },
              }}
              classes={{
                virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
              }}
            />
            <EmployeeActionsGroup
              employee={selectedEmployee}
              showEditEmployeeInfoModal={showEditEmployeeInfoModal}
              openArchiveEmployeeModal={openArchiveEmployeeModal}
            />
          </Box>
        </Stack>
      </Container>
      <EmployeeAddModal
        key={addEmployeeModalKey}
        open={showAddEmployeeModal}
        handleClose={handleCloseAddNewPersonModal}
        formMode={FormModeContext.ADD}
        refetchEmployees={refetchCollaboratorsQuery}
      />
      <EmployeeEditModal
        key={editEmployeeModalKey}
        employee={selectedEmployee}
        open={showEditEmployeeModal}
        formMode={FormModeContext.EDIT}
        handleClose={handleCloseEditEmployeeInfoModal}
        leftBottomAction={
          <GAButton
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Archive employee',
              purpose: 'Archives an employee',
            }}
            id="archive-button"
            color="error"
            onClick={openArchiveEmployeeModal}
          >
            <Typography variant="button">
              <strong>Archive employee</strong>
            </Typography>
          </GAButton>
        }
        refetchEmployees={refetchCollaboratorsQuery}
      />
      <ArchiveEmployeeModal
        open={showArchiveEmployeeModal}
        onSubmit={archiveEmployee}
        onCancel={() => setShowArchiveEmployeeModal(false)}
      />
      <RetrievalConfirmationModal
        open={showRetrievalConfirmationModal}
        handleClose={handleCloseRetrievalConfirmationModal}
      />
    </>
  );
};

export default Employees;
