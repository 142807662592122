/* eslint-disable security/detect-object-injection */
/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ManagementDashboardToggle from 'components/ManagementToggle';
import Spacer from 'components/Spacer';
import { UserPermissions } from 'global-constants';
import { usePermissionRedirect } from 'hooks/usePermissionRedirect';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums';
import useAssetManagementFilters from './hooks/useAssetManagementFilters';
import AssetMgmtDashSearchBar from './components/AMDashSearchBar';
import AssetMgmtDashFilters from './components/AMDashFilters';
import AssetManagementActionButtons from './components/AMDashActionButtons';
import * as AssetManagementStyles from './styles';
import * as AssetManagementConstants from './constants';
import AMComplexFilterBank from './components/AMComplexFilterBank';
import AMDashComplexFilterToggle from './components/AMDashComplexFilterToggle';
import AMDashTableCombo from './components/AMDashTableCombo';

const AssetManagement = () => {
  const userPermissions = useGetUserPermissions();
  usePermissionRedirect(
    userPermissions,
    UserPermissions.ASSET__MANAGEMENT__VIEW,
  );

  const styles = AssetManagementStyles;
  const fixed = AssetManagementConstants;

  const apiRef = useGridApiRef();
  // Complex Filter Feature-Flagging
  const {
    OnFlagTrueWrapper: ShowIfComplexFilterEnabled,
    OnFlagFalseWrapper: ShowIfComplexFilterDisabled,
  } = useFeatureFlagService(FeatureFlagNames.COMPLEX_ASSET_FILTERING, {
    debugFlags: true,
  });

  // OLD ASM FILTERS
  const {
    reducedFilterFields,
    setFilterField,
    resetFilterField,
    resetFilterFields,
    resetAllFields,
  } = useAssetManagementFilters();

  // Manual filter bar resetter
  const [clearFiltersBar, setClearFiltersBar] = React.useState<boolean>(false);

  return (
    <Container
      data-testid={fixed.DATA_TESTID_ASSET_MGMT_DASH}
      sx={styles.AMDashContainerSx}
    >
      <Stack
        data-testid={fixed.DATA_TESTID_ASSET_MGMT_TOP_STACK}
        direction="column"
      >
        <ManagementDashboardToggle />
        <Spacer height="16px" />
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={styles.MainHeaderSx}>Asset Management</Typography>
          <AssetManagementActionButtons />
        </Stack>
        <Spacer height="16px" />
      </Stack>
      <Stack
        data-testid={fixed.DATA_TESTID_ASSET_MGMT_MID_STACK}
        direction="column"
      >
        <Stack direction="row" justifyContent="space-between">
          <AssetMgmtDashSearchBar
            setFilterField={setFilterField}
            resetFilterFields={resetFilterFields}
            resetAllFields={resetAllFields}
            setClearFiltersBar={setClearFiltersBar}
          />

          <ShowIfComplexFilterEnabled>
            <AMDashComplexFilterToggle />
          </ShowIfComplexFilterEnabled>
        </Stack>

        <ShowIfComplexFilterDisabled>
          <AssetMgmtDashFilters
            filterTable={reducedFilterFields}
            setFilterField={setFilterField}
            resetFilterField={resetFilterField}
            resetFilterFields={resetFilterFields}
            clearFiltersBar={clearFiltersBar}
            setClearFiltersBar={setClearFiltersBar}
          />
        </ShowIfComplexFilterDisabled>
        <Spacer height="16px" />
      </Stack>
      <Stack
        data-testid={fixed.DATA_TESTID_ASSET_MGMT_END_STACK}
        direction="column"
      >
        <ShowIfComplexFilterEnabled>
          <AMComplexFilterBank />
        </ShowIfComplexFilterEnabled>
        <AMDashTableCombo reducedFilterFields={reducedFilterFields} />
        <Spacer height="32px" />
      </Stack>
    </Container>
  );
};

export default AssetManagement;
