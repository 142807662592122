import { capitalize } from 'lodash';
import { StatusTransitionConfigTable } from 'types';
import { AssetDetailStatus } from 'pages/AssetDetails/constants';

const FALLBACK = 'FALLBACK';

const ACTIVE_GREEN_STATUSES: string[] = [AssetDetailStatus.IN_INVENTORY];
const ACTIVE_GREEN = '#7A8450';

const ACTIVE_CYAN_STATUSES: string[] = [AssetDetailStatus.ALLOCATED, AssetDetailStatus.TRANSFERRED, AssetDetailStatus.INTAKE];
const ACTIVE_CYAN = '#4DB7C6';

const ACTIVE_GREY_STATUSES: string[] = [AssetDetailStatus.RECYCLED, AssetDetailStatus.SOLD, AssetDetailStatus.DONATED, AssetDetailStatus.LOST];
const ACTIVE_GREY = '#E0E0E0';

const ACTIVE_ORANGE_STATUSES: string[] = [];
const ACTIVE_ORANGE = '#F38A34';

const ACTIVE_YELLOW_STATUSES: string[] = [
  AssetDetailStatus.PENDING_ALLOCATION, AssetDetailStatus.PENDING_CERTIFICATION, AssetDetailStatus.PENDING_RECYCLE,
  AssetDetailStatus.PENDING_REPAIR, AssetDetailStatus.PENDING_SALE, AssetDetailStatus.PROCESSING_DEVICE,
  AssetDetailStatus.QUOTE_IN_PROGRESS, AssetDetailStatus.PENDING_RETRIEVAL, AssetDetailStatus.PENDING_WIPE,
];
const ACTIVE_YELLOW = '#F9C54D';

const ACTIVE_RED_STATUSES: string[] = [ 
  AssetDetailStatus.ACTIVATION_LOCKED, AssetDetailStatus.RECOVERY_LOCKED, AssetDetailStatus.HOLD,
  AssetDetailStatus.MDM_LOCKED, AssetDetailStatus.IN_INVENTORY_DEVICE_ISSUE, AssetDetailStatus.PENDING_QUOTE_APPROVAL,
];
const ACTIVE_RED = '#F27058';


const ICON_WHITE = '#FFFFFF';
const ICON_BLACK = '#131414';

export const COLOR_STATUS_TUPLES: [string, string[]][] = [
  [ACTIVE_GREEN, ACTIVE_GREEN_STATUSES],
  [ACTIVE_CYAN, ACTIVE_CYAN_STATUSES],
  [ACTIVE_ORANGE, ACTIVE_ORANGE_STATUSES],
  [ACTIVE_YELLOW, ACTIVE_YELLOW_STATUSES],
  [ACTIVE_RED, ACTIVE_RED_STATUSES],
  [ACTIVE_GREY, ACTIVE_GREY_STATUSES],
];

export const IRREGULAR_DISPLAY_NAME: Record<string, string> = {
  [AssetDetailStatus.IN_INVENTORY]: 'In inventory',
  [AssetDetailStatus.QUOTE_IN_PROGRESS]: 'Quote in progress',
  [AssetDetailStatus.MDM_LOCKED]: 'MDM Locked',
  [AssetDetailStatus.IN_INVENTORY_DEVICE_ISSUE]: 'In inventory - device issue',
  [FALLBACK]: FALLBACK,
};

export const ICON_COLOR_LOOKUP: Record<string, string> = {
  [ACTIVE_GREEN]: ICON_WHITE,
  [ACTIVE_CYAN]: ICON_WHITE,
  [ACTIVE_GREY]: ICON_BLACK,
  [ACTIVE_YELLOW]: ICON_BLACK,
  [ACTIVE_RED]: ICON_WHITE,
};

export const INITIAL_CONFIG_TABLE = {
  [FALLBACK]: {
    activeBGColor: ACTIVE_CYAN,
    activeTextColor: ICON_WHITE,
    displayStatus: FALLBACK,
  },
};

export const ASSET_STATUS_TRANSITION_CONFIG = COLOR_STATUS_TUPLES.reduce((acc, next) => {
  const [color, statuses] = next;
  if (statuses.length === 0) return acc;

  statuses.forEach((status: string) => {
    const displayStatus = status in IRREGULAR_DISPLAY_NAME ? IRREGULAR_DISPLAY_NAME[status] : status.split('_').map(capitalize).join(' ');

    acc[status as keyof typeof acc] = {
      activeBGColor: color,
      activeTextColor: ICON_COLOR_LOOKUP[color],
      displayStatus,
    };
  });
  return acc;
}, INITIAL_CONFIG_TABLE) as unknown as StatusTransitionConfigTable;
