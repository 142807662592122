/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProcurementFlowStep } from 'pages/Procurement/hooks/useProcurementService/constants';
import * as BuyAndHoldStyles from 'pages/BuyAndHold/styles';
import { Button, Footer } from 'components';
import BuyAndHoldBanner from 'pages/BuyAndHold/components/Banner';
import BuyAndHoldCTA from './components/BuyAndHoldCTA';
import DepotTable from './components/DepotTable';
import { DepotViewTestId } from './constants';
import * as DepotViewStyles from './styles';
import { DepotViewProps } from './types';

const DepotView = (props: DepotViewProps) => {
  const parentStyles = BuyAndHoldStyles;
  const styles = DepotViewStyles;
  const [depotTableKey, setDepotTableKey] = React.useState<string>(
    'depot-table-key-unique'
  );

  const onCancelButtonClick = () => {
    setDepotTableKey(nanoid());
    props.workflow.removeSelectedDepot();
  };

  const onMarketplaceButtonClick = () => {
    props.workflow.updateProcurementFlowStep(
      ProcurementFlowStep.PRODUCT_CATALOG
    );
  };

  const showDepotSelectFooter = () =>
    props.workflow.selectedDepotLocation?.id &&
    props.workflow.flowStep === ProcurementFlowStep.COLLABORATOR_VIEW;

  return (
    <>
      <Container
        data-testid="depot-view-container-abc123"
        sx={parentStyles.MainContainerSx}
      >
        <Stack
          sx={parentStyles.getContentStackSx()}
          direction="column"
          data-testid="depot-view-content-stack-def456"
        >
          <BuyAndHoldBanner data-testid="buy-and-hold-banner-ghi789" />
          <Stack
            sx={styles.DepotViewLowerStackSx}
            data-testid="depot-view-lower-stack-jkl012"
          >
            <DepotTable
              key={depotTableKey}
              workflow={props.workflow}
              data-testid="depot-table-mno345"
            />
            <BuyAndHoldCTA data-testid="buy-and-hold-cta-pqr678" />
          </Stack>
        </Stack>
      </Container>
      {showDepotSelectFooter() ? (
        <Footer data-testid="depot-select-footer-stu901">
          <Container data-testid="footer-container-vwx234">
            <Typography
              variant="caption"
              color="colors.offWhite"
              data-testid="footer-typography-yza567"
            >
              1 depot selected
            </Typography>
          </Container>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Cancel',
              purpose: 'Cancels Depot Selection',
            }}
            variant="outlined"
            onClick={onCancelButtonClick}
            data-testid="cancel-button-bcd890"
          >
            Cancel
          </Button>
          <Button
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Continue to marketplace',
              purpose: 'Continues to marketplace',
            }}
            variant="contained"
            sx={styles.DepotViewContinueButtonSx}
            onClick={onMarketplaceButtonClick}
            data-testid="continue-button-efg123"
          >
            <Typography data-testid="button-typography-hij456">
              Continue to marketplace
            </Typography>
          </Button>
        </Footer>
      ) : null}
    </>
  );
};

export default DepotView;