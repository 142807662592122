import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import { useCursorBasedPagination } from 'hooks/useCursorBasedPagination';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SEARCH_MERCHANDISE_ITEMS } from './queries';
import { ContentDivSx, DataGridBoxSx, MainStackSx } from './styles';
import { useGetMerchandiseColumns } from './hooks/useGetMerchandiseColumns';
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_PAGE_SIZE_OPTIONS,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  SortDirection,
} from 'global-constants';
import { FAKE_ROW_COUNT } from 'pages/Assets/constants';
import { SortOrder } from 'types';
import { ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME } from 'components/DataGrid/constants';

const Merchandise = () => {
  //Pagination
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_PAGE_SIZE);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize,
    });
  const cursorBasedPagination = useCursorBasedPagination();
  const disableBackPageButton = cursorBasedPagination.shouldDisableBackButton();
  const disableNextPageButton = cursorBasedPagination.shouldDisableNextButton();

  // Sorting
  const [ordersSort, setOrdersSort] = React.useState<SortOrder | null>(null);

  const userOrganization = useGetUserOrganization();

  const { data, loading } = useSafeQuery(SEARCH_MERCHANDISE_ITEMS, {
    variables: {
      organizationId: userOrganization?.id,
      limit: pageSize,
      offset: cursorBasedPagination.getCurrentCursorOffset(),
      ...(ordersSort && {
        [ordersSort.direction]: ordersSort.field,
      }),
    },
    skip: !userOrganization?.id,
    onCompleted: (data) => {
      const newCursorOffset = data?.searchMerchandiseItems?.offset;
      cursorBasedPagination.addCursor(newCursorOffset);
    },
  });

  const merchandiseItems = data?.searchMerchandiseItems?.merchandiseItems || [];
  const merchandiseColumns = useGetMerchandiseColumns();

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    //The page from the model coming from the datagrid will always be different than the `current` page
    if (model.page > paginationModel.page) {
      cursorBasedPagination.incrementIndex();
    } else if (model.page < paginationModel.page) {
      cursorBasedPagination.decrementIndex();
    } else if (model.pageSize !== pageSize) {
      setPageSize(model.pageSize);
      cursorBasedPagination.resetState();
    }
    setPaginationModel(model);
  };

  const handleSortModelChange = (sortModel: GridSortModel): void => {
    cursorBasedPagination.resetState();

    if (!sortModel.length) {
      setOrdersSort(null);
      return;
    }

    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setOrdersSort({
      direction: serverSortDirection,
      field,
    });
  };

  return (
    <Container sx={ContentDivSx}>
      <Stack direction="column" sx={MainStackSx}>
        <Typography variant="h4" className="merchandise-view-title">
          Merchandise
        </Typography>
        <Typography className="merchandise-view-subtitle">
          Please view merchandise levels below.
        </Typography>
      </Stack>
      <Box sx={DataGridBoxSx}>
        <DataGrid
          pagination
          autoHeight={false}
          rows={merchandiseItems}
          columns={merchandiseColumns}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handleOnPaginationModelChange}
          hideFooterRowCount
          disableRowSelectionOnClick
          loading={loading}
          rowCount={FAKE_ROW_COUNT}
          pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
          initialState={{ pinnedColumns: { right: ['total'] } }}
          slotProps={{
            pagination: {
              labelDisplayedRows: () => <div />,
              nextIconButtonProps: {
                disabled: disableNextPageButton,
              },
              backIconButtonProps: {
                disabled: disableBackPageButton,
              },
            },
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          disableMultipleColumnsSorting
          classes={{
            virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
          }}
        />
      </Box>
    </Container>
  );
};

export default Merchandise;
