import React from 'react';
import { ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID } from 'pages/OrderDetails/constants';

const useGetHandleClickOrderItemsLink = () => {
  return React.useCallback(() => {
    const orderItemsTab = document.querySelector(`[data-selectid="${ORDER_DETAILS_ORDER_ITEMS_TAB_BUTTON_SELECT_ID}"]`) as HTMLElement;
    if (orderItemsTab) {
      orderItemsTab.click();
    }
  }, []);
};

export default useGetHandleClickOrderItemsLink;
