import { SxProps } from '@mui/material';

const ExceptionTypeFontSx: SxProps = {
  color: '#60605D',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
};

export const ExceptionTypeSx: SxProps = {
  ...ExceptionTypeFontSx,
  maxWidth: '380px',
  pb: '6px',
};

export const ExceptionOrderItemsLinkSx: SxProps = {
  ...ExceptionTypeFontSx,
  textDecoration: 'underline',
  '&: hover': {
    cursor: 'pointer',
  },
};

export const ExceptionContactSupportLinkSx: SxProps = {
  ...ExceptionTypeFontSx,
  textDecoration: 'underline',
  '&: hover': {
    cursor: 'pointer',
  },
};
