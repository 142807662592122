import { gql } from '@apollo/client';

export const getAssetStatusTransitions = (output: string) => gql`
  query getAssetStatusTransitions(
    $assetId: String!
    $limit: Int = 25
    $offset: Int = 0
    $orderDesc: String = "createdAt"
  ) {
    assetStatusTransitions(
      searchAssetStatusTransitionInput: {
        assetId: $assetId
        limit: $limit
        offset: $offset
        orderDesc: $orderDesc
      }
    ) {
      ${output}
    }
  }
`;
