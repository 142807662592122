import { RootState } from '../../index';

export const selectAuthErrorCode = (state: RootState) =>
  state.global.authErrorCode;
export const selectAuthToken = (state: RootState) => state.global.authToken;
export const selectGlobalNotificationBanner = (state: RootState) =>
  state.global.globalNotificationBanner;
export const selectGlobalAvatarPopover = (state: RootState) =>
  state.global.globalAvatarPopover;

export const selectGlobalAuth0Collaborator = (state: RootState) =>
  state.global.globalAuth0Collaborator;

export const selectGlobalAuth0Organization = (state: RootState) =>
  state.global.globalAuth0Organization;

export const selectGlobalLocation = (state: RootState) =>
  state.global.globalLocation;
